import React, { useContext, useEffect, useRef, useState } from "react";
import { PCContext } from "../../../Context/PCContext";
import { renderTitle } from "./Intro/ParticleRendering1";
import { DataContext } from "../../../Context/DataContext";
import useParticleScrollHandler from "../../../Hooks/Intro/useParticleScrollHandler";
import { renderLinks } from "./Intro/ParticleRendering2";
import ContactModal from "../../../Components/Modal/Modals/ContactModal";
import useWinSize from "../../../Hooks/useWinSize";
import NoParticleIntro from "./Intro/NoParticleIntro";

const IntroSection: React.FC = () => {
  const { p, initialized, pSupported } = useContext(PCContext);
  const disabledRef = useRef(false);
  const curFrame = useRef(0);
  const [formOpened, setFormOpened] = useState(false);
  const [rendered, setRendered] = useState(false);
  const { particleImages, piSelected } = useContext(DataContext);
  const { isMobile, isTablet } = useWinSize();

  const { renderSection } = useParticleScrollHandler({
    disabledRef,
    rendered,
    formOpened,
  });

  useEffect(() => {
    if (p?.ready && pSupported) {
      if (
        particleImages.length > 0 &&
        particleImages?.[0]?.image &&
        renderSection === 0
      ) {
        renderTitle(p, particleImages, piSelected, disabledRef, 0);
        setRendered(true);
      }
      if (renderSection === 1) {
        renderLinks(p, particleImages, 0, setFormOpened, isMobile, isTablet);
      }
    }
  }, [initialized, particleImages, renderSection]);

  useEffect(() => {
    return () => {
      p?.deleteAllGroups();
      p?.setGroupLifetime([0], 0, 0);
    };
  }, []);

  return (
    <div style={{ height: pSupported ? "150vh" : "100vh" }}>
      <ContactModal opened={formOpened} onClose={() => setFormOpened(false)} />
      {!pSupported && <NoParticleIntro image={particleImages?.[0]?.image} />}
    </div>
  );
};

export default IntroSection;
