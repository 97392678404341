import { useCallback, useMemo, useRef, useState } from "react";

interface UseSelectorProps {}

interface UseSelector {
  selectClick: (index: number) => void;
  selectShiftClick: (index: number) => void;
  selectAll: (amt: number) => void;
  clearSelected: () => void;
  selected: { [key: number]: boolean };
  selectedAmt: number;
}

const useSelector: (props: UseSelectorProps) => UseSelector = () => {
  const [selected, setSelected] = useState<{ [key: number]: boolean }>({});
  const lastSelectedRef = useRef<number | undefined>(undefined);

  //toggle just one
  const selectClick = useCallback(
    (index: number) => {
      if (selected?.[index]) {
        const newSelected = { ...selected };
        delete newSelected[index];
        setSelected(newSelected);
      } else {
        const newSelected = { ...selected };
        newSelected[index] = true;
        setSelected(newSelected);
      }
      lastSelectedRef.current = index;
    },
    [selected, setSelected]
  );

  //shift click to select multiple, if clicking same it will act as the one above which just toggles one
  const selectShiftClick = useCallback(
    (index: number) => {
      if (lastSelectedRef.current === undefined) {
        lastSelectedRef.current = index;
        const newSelected = { ...selected };
        newSelected[index] = true;
        setSelected(newSelected);
        return;
      } else {
        const newSelected = { ...selected };
        if (lastSelectedRef.current !== index) {
          //loop through all the items in between and select them
          const min = Math.min(index, lastSelectedRef.current);
          const max = Math.max(index, lastSelectedRef.current);
          //if the first item was selected already and the final item wasn't then don't unselect the first.
          const unselectFirst =
            newSelected[index] !== newSelected[lastSelectedRef.current];
          for (let j = min; j <= max - min; j++) {
            if (!unselectFirst || j !== lastSelectedRef.current) {
              if (newSelected?.[j]) {
                delete newSelected[j];
              } else {
                newSelected[j] = true;
              }
            }
          }
        } else {
          if (newSelected?.[index]) {
            delete newSelected[index];
          } else {
            newSelected[index] = true;
          }
        }
        lastSelectedRef.current = index;
        setSelected(newSelected);
      }
    },
    [selected, setSelected]
  );

  const selectedAmt = useMemo(() => Object.keys(selected).length, [selected]);

  const selectAll = useCallback(
    (amt: number) => {
      //deselect all
      const newSelected = { ...selected };
      if (amt === selectedAmt) {
        for (let i = 0; i < amt; i++) {
          if (newSelected?.[i]) {
            delete newSelected[i];
          }
        }
      } else {
        for (let i = 0; i < amt; i++) {
          newSelected[i] = true;
        }
      }
      setSelected(newSelected);
    },
    [selected, setSelected, selectedAmt]
  );

  const clearSelected = useCallback(() => {
    setSelected({});
  }, [setSelected]);

  return {
    selectClick,
    selectShiftClick,
    selectAll,
    clearSelected,
    selected,
    selectedAmt,
  };
};

export default useSelector;
