import { useEffect, useRef } from "react";

interface UseDebounce {
  debounce: (cb: () => any) => void;
}

const useDebounce: (time: number) => UseDebounce = (time) => {
  const timeout = useRef<NodeJS.Timeout>();

  const debounce = (cb: () => any) => {
    if (timeout) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      cb();
    }, time);
  };

  return { debounce };
};

export default useDebounce;
