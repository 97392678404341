import OverflowContainer from "../Components/Container/OverflowContainer";
import { HomePageProvider } from "../Context/HomePageContext";
import AboutSection from "./Intro/Sections/AboutSection";
import ContactSection from "./Intro/Sections/Contact/ContactSection";
import IntroSection from "./Intro/Sections/IntroSection";
import ProjectsSection from "./Intro/Sections/ProjectsSection";

const PageController: React.FC = () => {
  return (
    <div id="projects-section">
      <HomePageProvider>
        <div></div>
        <IntroSection />
        {/* <IntroPage /> */}
        <ProjectsSection></ProjectsSection>
        <OverflowContainer style={{ paddingTop: "50px" }}>
          <AboutSection />
          <div className="space"></div>
        </OverflowContainer>
        <ContactSection />
      </HomePageProvider>
    </div>
  );
};

export default PageController;
