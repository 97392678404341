import { CSSProperties } from "react";

interface OverflowContainerProps {
  children: React.ReactNode | React.ReactNode[];
  style?: CSSProperties;
}

const OverflowContainer: React.FC<OverflowContainerProps> = ({
  children,
  style,
}) => {
  return (
    <div style={style} className="overflow-container">
      {children}
    </div>
  );
};

export default OverflowContainer;
