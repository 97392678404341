import Button from "../../Button/Button";
import Modal from "../Modal";

interface ParticleCompatibilityModalProps {
  opened: boolean;
  onContinue: () => void;
}

const ParticleCompatibilityModal: React.FC<ParticleCompatibilityModalProps> = ({
  opened,
  onContinue,
}) => {
  return (
    <Modal opened={opened}>
      <div className="compatibility-modal-container">
        <h3>Oops.</h3>
        <span>
          Your current browser or device may not be compatible with this
          website's full functionality. Please use a compatible desktop browser
          for the best experience. You may continue to the site without full
          functionality.
        </span>
        <Button onClick={onContinue}>Continue</Button>
      </div>
    </Modal>
  );
};

export default ParticleCompatibilityModal;
