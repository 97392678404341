import Button from "../../Button/Button";
import Modal from "../Modal";

interface DeleteConfirmModalProps {
  onCancel?: () => void;
  onConfirm?: () => void;
  open: boolean;
  error?: string;
}

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({
  open,
  error,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal opened={open} onClose={() => onCancel?.()}>
      <div
        style={{
          minWidth: "min(100vw,300px)",
          minHeight: "min(100vh,150px)",
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            wordBreak: "break-word",
            textAlign: "center",
          }}
        >
          Are you sure want to Delete
        </div>
        {error !== "" && <div>{error}</div>}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Button style={{ width: "40%" }} onClick={() => onConfirm?.()}>
            Yes
          </Button>
          <Button style={{ width: "40%" }} onClick={() => onCancel?.()}>
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmModal;
