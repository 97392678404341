import { motion } from "framer-motion";
import useWinSize from "../../../../Hooks/useWinSize";

const text1 = (
  <>
    <h1 style={{ fontFamily: "Poppins", margin: "0px", padding: "0px" }}>
      I'm a Explorer
    </h1>
    <p>
      Ever since I was a kid I loved seeing how the world worked. I would take
      apart gadget's and gizmos to figure out the inner mechanisms. As I got
      older technology became a bigger part of my life. A couple years after
      getting my first computer I found myself diving into the inner workings of
      computers.
    </p>
  </>
);

const text2 = (
  <>
    <h1
      style={{
        fontFamily: "Poppins",
        margin: "0px",
        padding: "0px",
        textAlign: "right",
      }}
    >
      Turned Creator
    </h1>
    <p>
      Little did I know the depths of the rabbit hole I had jumped into. Soon I
      began spending all of my free time learning and writing code. I first
      started out on a website called Scratch. After a while I found that
      scratch was limited in its capabilities, and soon moved on to creating
      projects in Java, C++, C#, Python, Javascript and more. I still love
      creating projects and diving into code to create something no one has.
      Pushing myself and the limits of what can be done.
    </p>
  </>
);

const AboutSectionText: React.FC = () => {
  const { isDesktop, isSmall, isMobile } = useWinSize();
  if (!isDesktop)
    return (
      <div>
        {text1}
        {text2}
      </div>
    );
  return (
    <div>
      {text1}
      {text2}
    </div>
  );
};

export default AboutSectionText;
