import { CSSProperties, createRef, useEffect, useState } from "react";

interface CollapsableProps {
  children: React.ReactNode | React.ReactNode[];
  opened: boolean;
  className?: string;
  style?: CSSProperties;
}

const Collapsable: React.FC<CollapsableProps> = ({
  children,
  opened,
  style,
  className,
}) => {
  const ref = createRef<HTMLDivElement>();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(ref.current?.offsetHeight ?? 0);
  }, [ref, ref.current?.offsetHeight]);

  return (
    <div
      className={`collapsable-container ${className ?? ""}`}
      style={{
        ...(opened ? { height: `${height}px` } : { height: "0px" }),
        ...style,
      }}
    >
      <div ref={ref}>{children}</div>
    </div>
  );
};

export default Collapsable;
