import { useMemo, useState } from "react";
import { ReactComponent as DownloadIcon } from "../../SVGs/download.svg";
import Modal from "../Modal/Modal";

interface DownloadFilesProps {
  fileLinks: { [key: string]: string }[];
}

const DownloadFiles: React.FC<DownloadFilesProps> = ({ fileLinks }) => {
  const [modalOpened, setModalOpened] = useState(false);

  const keys = useMemo(() => {
    const objects = Array(fileLinks.length);
    for (const i in fileLinks) {
      const obj = fileLinks[i];
      const keys = Object.keys(obj);
      if (keys.length === 1) {
        objects[i] = keys[0];
      }
    }
    return objects;
  }, [fileLinks]);

  return (
    <>
      {fileLinks.length === 1 && (
        <a href={fileLinks[0][keys[0]]}>
          <DownloadIcon stroke="#052858" className="pointer-cursor" />
        </a>
      )}
      {fileLinks.length > 1 && (
        <>
          <DownloadIcon
            stroke="#052858"
            onClick={() => setModalOpened(true)}
            className="pointer-cursor"
          />
          <Modal
            title="Download"
            onClose={() => setModalOpened(false)}
            opened={modalOpened}
          >
            <div className="download-modal-list">
              {fileLinks.map((val, i) => (
                <a
                  href={fileLinks[i][keys[i]]}
                  className="download-modal-item"
                  key={`modal-download-item-${keys[i]}`}
                >
                  <DownloadIcon
                    stroke="#052858"
                    width={"20"}
                    className="pointer-cursor"
                  />
                  <span>{keys[i]}</span>
                </a>
              ))}
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default DownloadFiles;
