import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import CollapsableTitle from "../../Collapsable/CollapsableTitle";
import Input from "../../Input/Input";
import { MComponent, listOfSupportedTags } from "../../../CMS/types";
import Chips from "../../Chips/Chips";
import SubChips, { SubChip } from "../../Chips/SubChips";
import {
  convertMCompChilds,
  convertObjectToMCompChilds,
  getFormPropsForType,
  stylesProperties,
} from "../util";
import Button from "../../Button/Button";
import Form from "../../Form/Form";

const editorSupportedTags = [...listOfSupportedTags, "content"];

interface SelectedEditorProps {
  component: MComponent;
  onComponentChange: (component: MComponent) => void;
  onParentSelect: () => void;
  onChildSelect: (item: number) => void;
}

const SelectedEditor: React.FC<SelectedEditorProps> = ({
  component,
  onComponentChange,
  onParentSelect,
  onChildSelect,
}) => {
  const [curComponent, setCurComponent] = useState(component);
  const [id, setId] = useState(component.id);
  const [style, setStyle] = useState<SubChip>(
    (component?.style ?? {}) as SubChip
  );
  const [classes, setClasses] = useState(
    component?.class?.length ?? 0 > 1 ? component?.class?.split(" ") : []
  );
  const [children, setChildren] = useState<SubChip>(
    convertMCompChilds(component.c)
  );

  const formProps = useMemo(() => getFormPropsForType(component), [component]);

  useEffect(() => {
    //make sure the component doesn't try to send a change back up if we just swapped the currently edited component
    if (curComponent === component) {
      convertObjectToMCompChilds(component, children);
      let nComp: MComponent = {
        ...component,
        class: classes?.join(" "),
        id: id,
        style: style as CSSProperties,
        c: convertObjectToMCompChilds(component, children),
      };

      onComponentChange(nComp);
    } else {
      setCurComponent(component);
    }
  }, [style, children, id, classes]);

  useEffect(() => {
    setId(component.id);
    setStyle((component?.style ?? {}) as SubChip);
    setClasses(
      component?.class?.length ?? 0 > 1 ? component?.class?.split(" ") : []
    );
    setChildren(convertMCompChilds(component.c));
  }, [component]);

  const handleChildClick = (val: string | number) => {
    //@ts-ignore
    //just make sure we aren't trying to go to a child that doesn't actually exist
    if (Object.keys(children?.[val])?.[0] !== "content") {
      onChildSelect(val as number);
    }
  };

  const handleFormChange = useCallback(
    (data: { [key: string]: any }) => {
      let nComp: MComponent = {
        ...component,
        ...data,
      };
      onComponentChange(nComp);
    },
    [onComponentChange]
  );

  //   const childChips = useMemo(
  //     () => convertMCompChilds(component.c),
  //     [component]
  //   );
  return (
    <CollapsableTitle title="Selected">
      <div className="editor-collapsable">
        <Input
          label="Id"
          id="editor-selected-id"
          onChange={(val: string) => setId(val)}
          value={id}
        />
        {formProps && <Form items={formProps} onChange={handleFormChange} />}
        <Chips
          label="Classes"
          chips={classes}
          onChange={(vals: string[]) =>
            setClasses(JSON.parse(JSON.stringify(vals)))
          }
          id="classes-container"
        ></Chips>
        <SubChips
          subChips={style}
          label="Styles"
          id="selected-styles"
          keySuggestions={stylesProperties}
          onChange={(vals: SubChip) =>
            setStyle(JSON.parse(JSON.stringify(vals)))
          }
          type="object"
        />
        <SubChips
          onItemClick={handleChildClick}
          subChips={children}
          label="Children"
          id="children"
          keySuggestions={editorSupportedTags}
          constrainKey={true}
          onChange={(vals: SubChip) => setChildren(vals)}
          type="list"
        />
        <Button onClick={() => onParentSelect()}>Go to Parent</Button>
      </div>
    </CollapsableTitle>
  );
};

export default SelectedEditor;
