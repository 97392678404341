import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { PCContext } from "../../Context/PCContext";
import { HomePageContext } from "../../Context/HomePageContext";

interface UseParticleScrollHandlerProps {
  disabledRef: React.MutableRefObject<boolean>;
  rendered: boolean;
  formOpened: boolean;
}

interface UseParticleScrollHandler {
  renderSection: number;
}

const useParticleScrollHandler: (
  props: UseParticleScrollHandlerProps
) => UseParticleScrollHandler = ({ disabledRef, rendered, formOpened }) => {
  const [renderSection, setRenderSection] = useState(0);
  const { p, pSupported } = useContext(PCContext);
  const ref = useRef({ scrollDy: 0, scrollPosY: 0, formOpened: formOpened });
  const { contactPageOffsetTop } = useContext(HomePageContext);
  //keep the particle group in the correct place when scrolling out of view

  useEffect(() => {
    ref.current.formOpened = formOpened;
    if (formOpened) {
      p?.groupAction(
        {
          type: "dest",
          yPos: `120%`,
          mode: "set",
        },
        0
      );
    } else {
      const pos =
        (contactPageOffsetTop?.current ?? 4000) - ref.current.scrollPosY + 425;
      p?.groupAction(
        {
          type: "dest",
          yPos: `${pos}px`,
          mode: "set",
        },
        0
      );
    }
  }, [formOpened]);

  useEffect(() => {
    const handleScroll = (e: Event) => {
      ref.current.scrollDy = ref.current.scrollPosY - window.scrollY;
      if (ref.current.scrollPosY > 930 && window.scrollY < 930) {
        p?.enableGroups([0]);
        if (disabledRef.current) {
          setTimeout(() => {
            disabledRef.current = false;
          }, 2000);
          p?.groupAction(
            {
              type: "dest",
              yPos: `${window.innerHeight / 2}px`,
              mode: "set",
            },
            0
          );
        } else {
          p?.groupAction(
            {
              type: "both",
              yPos: `${window.innerHeight / 2}px`,
              mode: "set",
            },
            0
          );
        }
      }

      if (ref.current.scrollPosY < 2000) {
        setRenderSection(0);
      }
      ref.current.scrollPosY = window.scrollY;
      if (
        ref.current.scrollPosY > 930 &&
        ref.current.scrollPosY <
          ((contactPageOffsetTop?.current ?? 4000) - 850) / 2
      ) {
        const pos = Math.max(
          window.innerHeight / 2 - (ref.current.scrollPosY - 930),
          -180
        );
        p?.enableGroups([0]);
        if (disabledRef.current) {
          setTimeout(() => {
            disabledRef.current = false;
          }, 2000);
          p?.groupAction(
            {
              type: "dest",
              yPos: `${pos}px`,
              mode: "set",
            },
            0
          );
        } else {
          p?.groupAction(
            {
              type: "both",
              yPos: `${pos}px`,
              mode: "set",
            },
            0
          );
        }
      } else if (
        ref.current.scrollPosY >
        ((contactPageOffsetTop?.current ?? 4000) - 850) / 2
      ) {
        //if at the bottom of the page
        if (
          ref.current.scrollPosY >
            (contactPageOffsetTop?.current ?? 4000) - 850 &&
          ref.current.formOpened === false
        ) {
          setRenderSection(1);
          const pos =
            (contactPageOffsetTop?.current ?? 4000) -
            ref.current.scrollPosY +
            425;
          p?.groupAction(
            {
              type: "dest",
              yPos: `${pos}px`,
              mode: "set",
            },
            0
          );
          //if approaching the bottom of the page
        } else {
          p?.groupAction(
            {
              type: "both",
              yPos: `120%`,
              mode: "set",
            },
            0
          );
        }
      }
    };
    if (rendered && pSupported) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [rendered]);
  // useEffect(() => {
  //   return () => {
  //     p?.setGroupLifetime([0], 0, 0);
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // useEffect(() => {
  //   const func = handleScroll;
  //   window.removeEventListener("scroll", handleScroll);
  // }, [pSupported]);

  return { renderSection };
};

export default useParticleScrollHandler;
