import OutlineContainer from "../../../Components/OutlineContainer/OutlineContainer";
import AboutSectionText from "./About/AboutSectionText";
import ExperienceText from "./Experience/ExperienceText";
import useWinSize from "../../../Hooks/useWinSize";
import LanguageSection from "./Languages/LanguageSection";

const AboutSection: React.FC = () => {
  const { isTablet, isDesktop } = useWinSize();
  return (
    <div className="center-within-margin">
      <OutlineContainer
        colSizes={["65%", "34%"]}
        title="About"
        noPaddingIndices={[1]}
        titlePos="left"
        style={{ marginBottom: "100px" }}
      >
        <AboutSectionText />

        {!isTablet && (
          <div style={{ height: "400px", width: "100%" }}>
            <img
              style={{
                // maxWidth: "600px",
                maxWidth: "100%",
                maxHeight: isTablet ? "400px" : "600px",
                // width: "100%",
                position: "absolute",
                top: isTablet ? "0px" : "50%",
                left: isTablet ? "50%" : "0px",
                borderRadius: "10px",
                transform: isTablet ? "translate(-50%,0)" : "translate(0,-50%)",
              }}
              src="https://luebke.app/images/util/profile.jpg"
            ></img>
          </div>
        )}
      </OutlineContainer>

      <LanguageSection />
      <OutlineContainer
        colSizes={["49%", "49%"]}
        title="Experience"
        titlePos="right"
      >
        <div style={{ height: "300px", width: "100%" }}>
          <img
            style={{
              maxWidth: "600px",
              maxHeight: "450px",
              width: "100%",
              position: "absolute",
              top: "0px",
              left: isTablet ? "50%" : "0px",
              borderRadius: "10px",
              transform: isTablet ? "translate(-50%,0)" : "translate(0,0)",
            }}
            src="https://luebke.app/images/switch/switch-preview-10.png"
          ></img>
        </div>
        <ExperienceText />
      </OutlineContainer>
    </div>
  );
};

export default AboutSection;
