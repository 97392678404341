import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const TJRedirectPage: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const currentPath = window.location.pathname;
    const newPath = currentPath.replace("/tj", "") || "/";
    navigate(newPath);
  });
  return <div></div>;
};

export default TJRedirectPage;
