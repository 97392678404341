import { useCallback, useContext, useState } from "react";

interface UseSelectedComponentProps {}

interface UseSelectedComponent {
  selected: number[];
  updateSelected: UpdateSelectedFunc;
}

export type UpdateSelectedFunc = (val: number[]) => void;

const useSelectedComponent: (
  props: UseSelectedComponentProps
) => UseSelectedComponent = () => {
  const [selected, setSelected] = useState<number[]>([]);

  const updateSelected: UpdateSelectedFunc = useCallback(
    (newSelected: number[]) => {
      setSelected(newSelected);
    },
    [setSelected]
  );

  return {
    selected,
    updateSelected,
  };
};

export default useSelectedComponent;
