import { useMemo } from "react";
import Copy from "../../../Input/Copy";
const baseURL = process.env.REACT_APP_BASE_URL;

interface UploadEditorProps {
  url: string;
  filename: string;
  onPreview: (url: string) => void;
  type: "image" | "video" | "file";
  onSelect?: () => void;
  onShiftSelect?: () => void;
  selecting?: boolean;
  selected?: boolean;
}

const UploadEditorItem: React.FC<UploadEditorProps> = ({
  onPreview,
  url,
  type,
  filename,
  onSelect,
  onShiftSelect,
  selected,
  selecting,
}) => {
  const imgURL = useMemo(() => {
    if (type === "video") {
      return (
        baseURL + "/images/thumbnails/" + filename.split(".")?.[0] + ".png"
      );
    }
    return url;
  }, [url, type, filename]);

  const onClick = (e: React.MouseEvent<HTMLImageElement>) => {
    if (e.shiftKey) {
      onSelect?.();
      // onShiftSelect?.();
      e.preventDefault();
    } else if (selecting || e.ctrlKey) {
      onSelect?.();
      e.preventDefault();
    } else {
      onPreview(url);
    }
  };

  return (
    <div className={`editor-upload-item-container`}>
      {selected && (
        <div
          className="editor-upload-item-overlay"
          onContextMenu={onClick}
          onClick={onClick}
        ></div>
      )}
      <div className="editor-upload-item-actions">
        <Copy value={url} />
      </div>
      <img
        onContextMenu={onClick}
        onClick={onClick}
        loading="lazy"
        className="editor-upload"
        src={imgURL}
      />
    </div>
  );
};

export default UploadEditorItem;
