import { useContext, useEffect, useState } from "react";
import Button from "../Button/Button";
import EditorPost from "./Sections/EditorPost";
import SelectedEditor from "./Sections/SelectedEditor";
import { MComponent } from "../../CMS/types";
import MC from "../../CMS/MComponent";
import UploadEditor from "./Sections/UploadEditor/UploadEditor";
import ProjectFooter from "../Project/ProjectFooter";
import { ReactComponent as EditIcon } from "../../SVGs/edit-icon.svg";
import { EditorContext } from "../../Context/EditorContext";
import CodeEditor from "./Sections/CodeEditor";
import CSSEditor from "./Sections/CSSEditor";

interface EditorProps {}

const Editor: React.FC<EditorProps> = () => {
  const [opened, setOpened] = useState(false);
  //@ts-ignore
  const {
    content,
    updateContent,
    saveProjectContent,
    updateSelected,
    selected,
    data,
  } = useContext(EditorContext);
  const [selectedComp, setSelectedComp] = useState<MComponent | null>(null);

  const getComp = (dt: MComponent) => {
    let comp = dt;
    for (const i in selected) {
      const idx = selected[i];
      if (comp.c && Array.isArray(comp.c)) {
        comp = comp.c[idx];
      }
    }
    return comp;
  };

  useEffect(() => {
    setSelectedComp(getComp(content));
  }, [selected, content]);
  //   const selected = component[1] as MComponent;

  const handleComponentChange = (nComp: MComponent) => {
    console.log(nComp);
    const copyData =
      selected.length === 0
        ? JSON.parse(JSON.stringify(nComp))
        : JSON.parse(JSON.stringify(content));
    //if we are at the top component we are just setting it to what was passed
    if (selected.length !== 0) {
      let item = copyData;
      for (let i = 0; i < selected.length - 1; i++) {
        const indx = selected[i];
        item = item.c[indx];
      }
      item.c[selected[selected.length - 1]] = JSON.parse(JSON.stringify(nComp));
    }
    updateContent(copyData);
  };

  const handleParentSelect = () => {
    const test = JSON.parse(JSON.stringify(selected));
    test.splice(test.length - 1, 1);
    updateSelected(test);
  };

  const handleChildSelect = (val: number) => {
    const test = JSON.parse(JSON.stringify(selected));
    test.push(val);
    updateSelected(test);
  };

  return (
    <>
      <div className={`editor-drawer ${opened ? "opened" : ""}`}>
        <div className="editor-tab-control-container">
          <div
            className="editor-tab-control"
            onClick={() => setOpened(!opened)}
          >
            <EditIcon style={{ width: "75%" }} />
          </div>
        </div>
        <div className="editor-drawer-container">
          <div className="flex-right p-10">
            <Button onClick={() => saveProjectContent(content)}>Save</Button>
          </div>
          <EditorPost />
          <CSSEditor />
          <CodeEditor />
          {selectedComp && (
            <SelectedEditor
              onChildSelect={handleChildSelect}
              onComponentChange={handleComponentChange}
              component={selectedComp}
              onParentSelect={handleParentSelect}
            />
          )}
          <UploadEditor title="Image" id="image-editor" type="image" />
          <UploadEditor title="Video" id="video-editor" type="video" />
          <UploadEditor title="File" id="file-editor" type="file" />
        </div>
      </div>
      <MC components={content} layer={0} />
      {/* <ProjectFooter /> */}
    </>
  );
};

export default Editor;
