import { CSSProperties, useState } from "react";
import Collapsable from "./Collapsable";
import { ReactComponent as ArrowIcon } from "../../SVGs/arrow.svg";

interface CollapsableTitleProps {
  title: string;
  initiallyOpened?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  useSeperator?: boolean;
  className?: string;
  style?: CSSProperties;
  titleClassName?: string;
  titleStyle?: CSSProperties;
  containerClassName?: string;
  containerStyle?: CSSProperties;
}

const CollapsableTitle: React.FC<CollapsableTitleProps> = ({
  children,
  title,
  useSeperator = true,
  initiallyOpened = false,
  className,
  style,
  titleClassName,
  titleStyle,
  containerStyle,
  containerClassName,
}) => {
  const [opened, setOpened] = useState(initiallyOpened);
  return (
    <div className={className} style={style}>
      <div
        style={containerStyle}
        className={`collapsable-title-container ${containerClassName ?? ""}`}
        onClick={() => setOpened(!opened)}
      >
        <h3
          style={titleStyle}
          className={`collapsable-title-container-title ${
            titleClassName ?? ""
          }`}
        >
          {title}
        </h3>
        <ArrowIcon
          className="transform-transition"
          style={{ transform: `rotate(${opened ? 0 : -90}deg)` }}
        />
      </div>
      {useSeperator && <div className="collapsable-seperator"></div>}
      <Collapsable style={{ padding: "0px 15px" }} opened={opened}>
        {children}
      </Collapsable>
    </div>
  );
};

export default CollapsableTitle;
