import CheckAppStatus from "./Components/Loading/CheckAppStatus";
import routes from "./Components/Router/routes";
import { DataProvider } from "./Context/DataContext";
import { ParticleContainer } from "./Particles/ParticleContainer";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
const basename = process.env?.REACT_APP_BASE_NAME ?? "/";
const router = createBrowserRouter(routes, { basename: basename });

const Page = () => {
  return (
    <div>
      <ParticleContainer>
        <DataProvider>
          <CheckAppStatus>
            <RouterProvider router={router} />
          </CheckAppStatus>
        </DataProvider>
      </ParticleContainer>
    </div>
  );
};

export default Page;
