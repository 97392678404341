import { CSSProperties, createRef, useEffect, useState } from "react";
import { ReactComponent as DeleteIcon } from "../../SVGs/delete.svg";
import Suggestions from "../Suggestions/Suggestions";
import useDragDropList from "../../Hooks/useDragDropList";

export type SubChip = { [key: string]: string }[] | { [key: string]: string };

interface SubChipsProps {
  id: string;
  subChips?: SubChip;
  keySuggestions?: string[];
  valueSuggestions?: string[];
  valueKeySuggestions?: { [key: string]: string[] };
  onChange: (vals: SubChip) => void;
  onItemClick?: (key: string | number) => void;
  label?: string;
  style?: CSSProperties;
  className?: string;
  type: "list" | "object";
  useDraggable?: boolean;
  constrainKey?: boolean;
  constrainValue?: boolean;
}

const SubChips: React.FC<SubChipsProps> = ({
  id,
  label,
  onChange,
  onItemClick,
  style,
  subChips,
  className,
  constrainKey = false,
  constrainValue = false,
  keySuggestions,
  valueSuggestions,
  valueKeySuggestions,
  useDraggable = true,
  type,
}) => {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [step, setStep] = useState(0);
  const [editing, setEditing] = useState<number | string>(-1);
  const [editingVal, setEditingVal] = useState("");
  const refArea = createRef<HTMLTextAreaElement>();
  const subChipsList = (subChips as { [key: string]: string }[]) ?? [];
  const subChipsObject = (subChips as { [key: string]: string }) ?? {};

  //draggableStuff
  const { onDrag, onDragOver, onDrop, onDragLeave } = useDragDropList({
    list: subChipsList,
    onChange: (val: any[]) => onChange(val),
    useDragNDrop: type === "list" && useDraggable,
  });

  //once the user has added a key and a value then we will change the subChips based off it's type
  const addNewVal = (iKey?: string, iValue?: string) => {
    if (type === "list") {
      const obj: any = {};
      obj[iKey ?? key] = iValue ?? value;
      onChange([...(subChipsList ?? []), obj]);
    } else {
      const newObj: any = {};
      newObj[iKey ?? key] = iValue ?? value;
      const test = { ...subChipsObject, ...newObj };
      onChange(test);
    }
    setKey("");
    setValue("");
  };

  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Enter" &&
      ((step === 0 && key !== "") || (step === 1 && value !== ""))
    ) {
      if (step === 0) {
        if ((constrainKey && keySuggestions?.includes(key)) || !constrainKey)
          setStep(1);
      } else {
        //if the constrain parameter is turned on the user will only be able to put in what the values have been suggested
        if (
          (constrainValue &&
            (valueSuggestions?.includes(key) ||
              valueKeySuggestions?.[key]?.includes(value))) ||
          !constrainValue
        )
          addNewVal();
        setStep(0);
      }
    }
  };

  const handleChipClick = (item: string | number) => {
    onItemClick?.(item);
  };

  const handleInputChange = (val: string) => {
    if (step === 0) {
      setKey(val);
    } else {
      setValue(val);
    }
  };

  const handleChipDeletion = (indx: number | string) => {
    if (type === "list") {
      const test = [...(subChipsList ?? [])];
      test.splice(indx as number, 1);
      onChange(test);
    } else {
      const test = { ...(subChipsObject ?? {}) };
      delete test[indx as string];
      onChange(test);
    }
  };

  const handleKeySuggestion = (val: string) => {
    setKey(val);
    setStep(1);
    // onChange([...(chips ?? []), val]);
  };

  const handleValueSuggestion = (val: string) => {
    addNewVal(key, val);
  };

  //when the user finishes editing a already created chip lets update the chip data
  const handleChipFinishEdit = () => {
    if (type === "list") {
      const test = [...(subChipsList ?? [])];
      //@ts-ignore
      test[editing][Object.keys(subChipsList?.[editing] ?? "")?.[0]] =
        editingVal;
      onChange(test);
    } else {
      const test = { ...(subChipsObject ?? {}) };
      test[editing] = editingVal;
      onChange(test);
    }
    setEditing(-1);
  };

  const handleCancel = () => {
    setStep(0);
    setKey("");
    setValue("");
  };

  //when the user double clicks a child to edit it.
  useEffect(() => {
    if (refArea.current) {
      refArea.current.focus();
      if (type === "list") {
        setEditingVal(
          //@ts-ignore
          subChipsList?.[editing]?.[
            //@ts-ignore
            Object.keys(subChipsList?.[editing] ?? {})?.[0]
          ]
        );
      } else {
        setEditingVal(subChipsObject?.[editing]);
      }
    }
  }, [editing]);
  return (
    <div style={style} className={"chips-container" + className}>
      {label && (
        <label className="input-label" htmlFor={id}>
          {label}
        </label>
      )}
      <label htmlFor={id} className="portfolio-input-border chips">
        {/* Render all the subchips if the type is a list */}
        {type === "list" &&
          (subChipsList ?? []).map((val, i) => (
            <div
              onDragOver={(e) => onDragOver(e, i)}
              draggable={true}
              onDragLeave={onDragLeave}
              onDrag={() => onDrag(i)}
              onDrop={() => onDrop(i)}
              style={{ paddingLeft: "0px" }}
              className="chip-input-chip"
              key={`${id}-chips-${i}-${Object.keys(val ?? {})?.[0]}`}
            >
              <div
                onClick={() => handleChipClick(i)}
                className="chip-input-chip"
              >
                {Object.keys(val ?? {})?.[0]}
              </div>
              <div onDoubleClick={() => setEditing(i)}>
                {i !== editing ? val?.[Object.keys(val ?? {})?.[0]] ?? "" : ""}
                {i === editing && (
                  <textarea
                    ref={refArea}
                    onChange={(e) => setEditingVal(e.target.value)}
                    onBlur={handleChipFinishEdit}
                    value={editingVal}
                  />
                )}
              </div>
              <DeleteIcon
                onClick={() => handleChipDeletion(i)}
                className="chip-input-delete"
              />
            </div>
          ))}
        {/* Render all the subchips if the type is an object with key value pairs */}
        {type === "object" &&
          Object.keys(subChipsObject || {})?.map((val, i) => (
            <div
              style={{ paddingLeft: "0px" }}
              className="chip-input-chip"
              key={`${id}-chips-${i}-${val}`}
            >
              <div
                onClick={() => handleChipClick(val)}
                className="chip-input-chip"
              >
                {val}
              </div>
              <div onDoubleClick={() => setEditing(val)}>
                {val !== editing ? subChipsObject?.[val] : ""}
                {val === editing && (
                  <textarea
                    ref={refArea}
                    onChange={(e) => setEditingVal(e.target.value)}
                    onBlur={handleChipFinishEdit}
                    value={editingVal}
                  />
                )}
              </div>
              <DeleteIcon
                onClick={() => handleChipDeletion(val)}
                className="chip-input-delete"
              />
            </div>
          ))}
        {/* if the step is 0 render only the key chip */}
        {step === 0 && <div>{key}</div>}
        {/* now we have the key chip render both the key chip and the value as we are typing */}
        {step === 1 && (
          <div
            className="chip-input-chip editing"
            style={{ paddingLeft: "0px" }}
          >
            <div className="chip-input-chip">{key}</div>
            {value}
            <DeleteIcon
              onClick={() => handleCancel()}
              className="chip-input-delete"
            />
          </div>
        )}
        <input
          onKeyDown={handleKey}
          value={step === 0 ? key : value}
          onChange={(e) => handleInputChange(e.target.value)}
          id={id}
          className="chips-input"
        ></input>
        {step === 0 && keySuggestions && (
          <Suggestions
            onSelect={handleKeySuggestion}
            curVal={key}
            suggestions={keySuggestions}
            id={`sub-chip-key-suggestions-${id}`}
          />
        )}
        {step === 1 && (valueSuggestions || valueKeySuggestions) && (
          <Suggestions
            onSelect={handleValueSuggestion}
            curVal={value}
            suggestions={[
              ...(valueKeySuggestions?.[key] ?? []),
              ...(valueSuggestions ?? []),
            ]}
            id={`sub-chip-value-suggestions-${id}`}
          />
        )}
      </label>
    </div>
  );
};

export default SubChips;
