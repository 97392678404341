import { CSSProperties } from "react";

export interface MComponentBase {
  id: string;
  tag: SupportedTags;
  style?: CSSProperties;
  /** content */
  class?: string;
  /** content */
  c?: MComponent | MComponent[] | string | number;
}

export interface MComponentImg extends MComponentBase {
  tag: "img";
  src?: string;
  alt?: string;
}

export interface MComponentVid extends MComponentBase {
  tag: "video";
  src?: string;
  controls?: string;
  autoplay?: string;
  loop?: string;
  playsinline?: string;
  poster?: string;
}

export interface MComponentAnchor extends MComponentBase {
  tag: "a";
  href?: string;
}

export interface MComponentIcon extends MComponentBase {
  tag: "icon";
  icon: string;
}

export interface MComponentScrollTransition extends MComponentBase {
  tag: "ScrollTransition";
}

export interface MComponentProjectContainer extends MComponentBase {
  tag: "ProjectContainer";
  title?: string;
  year?: string;
  projectLink?: string;
  githubLink?: string;
  fileLinks?: [{ [key: string]: string }];
  colSizes: string[];
  noPadding?: number[];
}

export interface MComponentResourcesCarousal extends MComponentBase {
  carousalData: string[];
  tabPosition?: "left" | "right";
}

export const listOfSupportedTags = [
  "p",
  "a",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "img",
  "div",
  "icon",
  "ProjectContainer",
  "ScrollTransition",
  "ResourcesCarousal",
  "video",
];

export type SupportedTags = (typeof listOfSupportedTags)[number];

export type MComponent =
  | MComponentBase
  | MComponentAnchor
  | MComponentImg
  | MComponentProjectContainer
  | MComponentIcon;
