import { ImageLoaderImage } from "../../../../Particles/hooks/useImageLoader";
import { ParticleController } from "../../../../Particles/types";
import { ParticleTextInput } from "../../../../Particles/types/particleReader";

const imagePos = [
  { x: 200 + Math.random() * 400, y: 40 + Math.random() * 50 },
  { x: 0 + 180 + Math.random() * 200, y: Math.random() * 200 },
  { x: 180 + Math.random() * 200, y: 10 + Math.random() * 55 },
  { x: Math.random() * 200 + 350, y: Math.random() * 350 + 200 },
];

export const renderTitle = (
  p: ParticleController,
  images: ImageLoaderImage[],
  selected: number,
  disabledRef: React.MutableRefObject<boolean>,
  curFrame: number
) => {
  const defaultFontSize = 70;
  const curFontSize = 30 + 40 * (window.innerWidth / 1920);
  const dividend = curFontSize / defaultFontSize;
  if (curFrame === 0) {
    const text = "Click Me :)";
    p?.addInputGroup({
      group: 0,
      xPos: "50%",
      yPos: `${window.scrollY > 930 ? -130 : window.innerHeight / 2}px`,
      action: {
        type: "point",
        xPos: "50%",
        yPos: `${window.scrollY > 930 ? -130 : window.innerHeight / 2}px`,
        mode: "set",
      },
      allocatedParticles: 20000,
      radius: 1.5,
      prtclDstRng: 0.5,
      resPerc: 100,
      removeWhite: true,
      inputs: [
        {
          text: text,
          xPos: `50% + 1px`,
          color: "#000000FF",
          align: "center",
          fontSize: `${curFontSize}px`,
          font: "Poppins",
        } as ParticleTextInput,
        {
          text: text,
          xPos: `50% - 1px`,
          color: "#000000FF",
          align: "center",
          fontSize: `${curFontSize}px`,
          font: "Poppins",
        } as ParticleTextInput,
        {
          text: text,
          xPos: `50%`,
          yPos: `50% + 1px`,
          color: "#000000FF",
          fontSize: `${curFontSize}px`,
          align: "center",
          font: "Poppins",
        } as ParticleTextInput,
        {
          text: text,
          xPos: `50%`,
          yPos: `50% - 1`,
          color: "#000000FF",
          align: "center",
          fontSize: `${curFontSize}px`,
          font: "Poppins",
        } as ParticleTextInput,
        {
          text: text,
          xPos: `50%`,
          color: "rgba(255, 255, 255, 255)",
          align: "center",
          fontSize: `${curFontSize}px`,
          font: "Poppins",
        } as ParticleTextInput,
      ],
      clickCallback: () => {
        if (!disabledRef.current) {
          p.disableGroups([0]);
          disabledRef.current = !disabledRef.current;
          setTimeout(() => {
            renderTitle(p, images, selected, disabledRef, 1);
          }, 1000);
        } else {
          // p.enableGroups([0]);
        }
      },
    });
  } else if (curFrame === 1) {
    p?.addInputGroup({
      group: 0,
      xPos: "50%",
      yPos: `${window.scrollY > 930 ? -130 : window.innerHeight / 2}px`,
      allocatedParticles: 20000,
      radius: 2,
      prtclDstRng: 0.5,
      shufflePoints: true,
      resPerc: 100,
      removeWhite: true,
      inputs: [
        {
          text: ".",
          xPos: `50%`,
          color: "#000000FF",
          align: "center",
          fontSize: `${200}px`,
          font: "Poppins",
        } as ParticleTextInput,
      ],
      clickCallback: () => {
        if (!disabledRef.current) {
          p.disableGroups([0]);
        } else {
          p.enableGroups([0]);
        }
        disabledRef.current = !disabledRef.current;
        setTimeout(() => {
          renderTitle(p, images, selected, disabledRef, 1);
        }, 1000);
      },
    });
    setTimeout(() => {
      p.groupAction(
        {
          type: "dest",
          xPos: "0%",
          yPos: `-30%`,
          mode: "shift",
        },
        0
      );
    }, 20);
    setTimeout(() => {
      p.groupAction(
        {
          type: "dest",
          xPos: "30%",
          yPos: `-10%`,
          mode: "shift",
        },
        0
      );
    }, 80);
    setTimeout(() => {
      p.groupAction(
        {
          type: "dest",
          xPos: "10%",
          yPos: `10%`,
          mode: "shift",
        },
        0
      );
    }, 200);
    setTimeout(() => {
      p.groupAction(
        {
          type: "dest",
          xPos: "-5%",
          yPos: `20%`,
          mode: "shift",
        },
        0
      );
    }, 300);
    setTimeout(() => {
      p.groupAction(
        {
          type: "dest",
          xPos: "-10%",
          yPos: `2%`,
          mode: "shift",
        },
        0
      );
    }, 400);
    setTimeout(() => {
      renderTitle(p, images, selected, disabledRef, 2);
    }, 500);
  } else if (curFrame === 2) {
    p?.addInputGroup({
      group: 0,
      xPos: "50%",
      yPos: `${window.scrollY > 930 ? -130 : window.innerHeight / 2}px`,
      // action: {
      //   type: "point",
      //   xPos: "50%",
      //   yPos: `${window.scrollY > 930 ? -130 : window.innerHeight / 2}px`,
      //   mode: "set",
      // },
      shufflePoints: true,
      allocatedParticles: 20000,
      radius: 1.5,
      prtclDstRng: 0.25,
      resPerc: 100,
      removeWhite: true,
      inputs: [
        {
          text: "TJ Luebke",
          color: {
            image: images[0].image,
            repetition: "repeat",
          },
          xPos: `50% + ${120 * dividend}px`,
          font: "Poppins",
          align: "center",
          fontSize: `${curFontSize}px`,
          fillOffset: imagePos[selected - 1],
        } as ParticleTextInput,
        {
          text: "Hi, I'm",
          xPos: `${window.innerWidth / 2 + 2 * dividend - 170 * dividend}px`,
          color: "#000000FF",
          align: "center",
          fontSize: `${curFontSize}px`,
          font: "Poppins",
        } as ParticleTextInput,
        {
          text: "Hi, I'm",
          xPos: `${window.innerWidth / 2 - 2 * dividend - 170 * dividend}px`,
          color: "#000000FF",
          align: "center",
          fontSize: `${curFontSize}px`,
          font: "Poppins",
        } as ParticleTextInput,
        {
          text: "Hi, I'm",
          xPos: `${window.innerWidth / 2 - 170 * dividend}px`,
          yPos: `${window.innerHeight / 2 + 2 * dividend}px`,
          color: "#000000FF",
          fontSize: `${curFontSize}px`,
          align: "center",
          font: "Poppins",
        } as ParticleTextInput,
        {
          text: "Hi, I'm",
          xPos: `${window.innerWidth / 2 - 170 * dividend}px`,
          yPos: `${window.innerHeight / 2 - 2 * dividend}px`,
          color: "#000000FF",
          align: "center",
          fontSize: `${curFontSize}px`,
          font: "Poppins",
        } as ParticleTextInput,
        {
          text: "Hi, I'm",
          xPos: `${window.innerWidth / 2 - 170 * dividend}px`,
          color: "rgba(255, 255, 255, 255)",
          align: "center",
          fontSize: `${curFontSize}px`,
          font: "Poppins",
        } as ParticleTextInput,
        {
          text: "Software Developer - Web Developer - Game Designer",
          xPos: `${window.innerWidth / 2}px`,
          yPos: `${window.innerHeight / 2 + 30 * dividend}px`,
          color: "#000000",
          align: "center",
          fontSize: `${18 * dividend}px`,
          font: "Poppins",
        } as ParticleTextInput,
      ],
      clickCallback: () => {
        if (!disabledRef.current) {
          p.disableGroups([0]);
        } else {
          p.enableGroups([0]);
        }
        disabledRef.current = !disabledRef.current;
        // setTimeout(() => {
        //   p.enableGroups([0]);
        // }, 4000);
      },
    });
  }
};
