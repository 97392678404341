interface NoParticleIntroProps {
  image: HTMLImageElement | undefined;
}

const NoParticleIntro: React.FC<NoParticleIntroProps> = ({ image }) => {
  const defaultFontSize = 70;
  const curFontSize = 30 + 40 * (window.innerWidth / 1920);
  const dividend = curFontSize / defaultFontSize;
  return (
    <div className="no-particle-intro-container">
      <div
        style={{ fontSize: `${curFontSize}px` }}
        className="no-particle-top-intro-container"
      >
        <div
          style={{ fontSize: curFontSize, fontWeight: 700 }}
          className="outline-text"
        >
          Hi, I'm
        </div>
        <div
          style={{
            background: `url('${image?.src ?? ""}')`,
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            backgroundSize: "100%",
          }}
          className="image-clip-container"
        >
          <span
            className="image-clip-text"
            style={{
              fontSize: curFontSize,
              color: "transparent",
            }}
          >
            TJ Luebke
          </span>
        </div>
      </div>
      <div className="no-particle-intro-sub-heading">
        <span style={{ fontSize: `${18 * dividend}px`, fontWeight: 700 }}>
          Software Developer - Web Developer - Game Designer
        </span>
      </div>
    </div>
  );
};

export default NoParticleIntro;
