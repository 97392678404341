import { useContext, useEffect } from "react";
import Editor from "../../Components/Editor/Editor";
import { EditorContext } from "../../Context/EditorContext";

interface AdminEditorProps {}

const AdminEditor: React.FC<AdminEditorProps> = () => {
  const { loadProject, content, data, loadedData } = useContext(EditorContext);

  useEffect(() => {
    loadProject();
  }, []);

  return <div>{loadedData && <Editor />}</div>;
};

export default AdminEditor;
