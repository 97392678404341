import { createContext, useEffect, useMemo, useState } from "react";
import axios, { AxiosResponse } from "axios";
import useFetcher, { FetcherData } from "../Hooks/useFetcher";
import { PreviewProjectData } from "../Resources/types";
import useAuth, {
  UserPermissions,
  defaultUserPermissions,
} from "../Hooks/useAuth";
import useParticleImageLoad from "../Hooks/Intro/useParticleImageLoad";
import { ImageLoaderImage } from "../Particles/hooks/useImageLoader";
const baseURL = process.env.REACT_APP_BASE_URL;

interface DataContextProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

interface DataProps {
  projects: PreviewProjectData[];
  token?: string;
  permissions: UserPermissions;
  particleImages: ImageLoaderImage[];
  piSelected: number;
  loaded: boolean;
  updateToken: (newToken: string | undefined) => void;
  updatePermissions: (newPermissions: UserPermissions) => void;
  createTokenRefreshTimeout: (date: Date) => void;
  fetcher: (
    url: string,
    data?: FetcherData,
    cb?: (response: AxiosResponse) => void,
    cbErr?: (reason: any) => void
  ) => any;
}

export const DataContext = createContext<DataProps>({
  projects: [],
  loaded: false,
  particleImages: [],
  piSelected: 0,
  updateToken: () => {},
  fetcher: () => {},
  updatePermissions: () => {},
  createTokenRefreshTimeout: () => {},
  permissions: defaultUserPermissions,
});

export const DataProvider: React.FC<DataContextProviderProps> = ({
  children,
}) => {
  const [projectsLoaded, setProjectsLoaded] = useState(false);
  const [projects, setProjects] = useState([]);
  const {
    token,
    permissions,
    updateToken,
    updatePermissions,
    createTokenRefreshTimeout,
    authLoaded,
  } = useAuth();

  const { particleImages, piLoaded, piSelected } = useParticleImageLoad();

  const { fetcher } = useFetcher({ token });

  const getProjects = async () => {
    try {
      const result = await axios.get(`${baseURL}/posts`);
      if (result?.data) {
        setProjects(result.data);
      }
      setProjectsLoaded(true);
    } catch (err) {
      //TODO: handle error maybe make it so the user knows the projects weren't loading
      setProjectsLoaded(true);
    }
  };

  const loaded = useMemo(() => {
    if (projectsLoaded && authLoaded && piLoaded) {
      return true;
    }
    return false;
  }, [projectsLoaded, authLoaded, piLoaded]);

  useEffect(() => {
    //if we don't have any projects loaded into the context then load them up.
    if (projects.length === 0) {
      getProjects();
    }
  }, []);

  const value: DataProps = useMemo(() => {
    return {
      piSelected,
      particleImages,
      loaded: loaded,
      projects: projects,
      token: token,
      updateToken: updateToken,
      fetcher,
      permissions,
      updatePermissions,
      createTokenRefreshTimeout,
    };
  }, [
    loaded,
    piSelected,
    particleImages,
    projects,
    token,
    permissions,
    updatePermissions,
    fetcher,
    updateToken,
    createTokenRefreshTimeout,
  ]);

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
