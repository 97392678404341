import { useMemo } from "react";
import { SelectSpinnerOptions } from "./type";
import ArcScrollProgress from "./ArcScrollProgress";
import SpinnerImage from "./SpinnerImage";
import { Link } from "react-router-dom";
import useWinSize from "../../Hooks/useWinSize";

interface SpinnerTextProps {
  options: SelectSpinnerOptions[];
  angleIncrement: number;
  dis: number;
  selected: number;
  offsetX: number;
  scrollProgress: number;
  fontSize: string;
  resting: boolean;
}

interface OffsetTextItemProps {
  angleIncrement: number;
  index: number;
  dis: number;
  selected: boolean;
  selectedIndex: number;
  title: string;
  subTitle: string;
  fontSize: string;
  showNonSelected: boolean;
}

const OffsetTextItem: React.FC<OffsetTextItemProps> = ({
  angleIncrement,
  dis,
  selected,
  selectedIndex,
  index,
  title,
  subTitle,
  fontSize,
  showNonSelected,
}) => {
  const angleDiv1 = useMemo(() => 180 / angleIncrement, [angleIncrement]);
  const angleDiv2 = useMemo(() => 90 / angleIncrement, [angleIncrement]);
  const angle = useMemo(() => angleIncrement * index, [index, angleIncrement]);
  const color = useMemo(
    () => (selected ? `#000000FF` : `#FFFFFF00`),
    [selected]
  );
  const opacity = useMemo(
    () =>
      !showNonSelected && !selected
        ? 0
        : Math.max(angleDiv2 - Math.abs(selectedIndex - index), 0) / angleDiv2,
    [selectedIndex, index, showNonSelected]
  );
  const scale = useMemo(
    () => Math.max(angleDiv1 - Math.abs(selectedIndex - index), 0) / angleDiv1,
    [selectedIndex, index]
  );
  return (
    <div
      className="spinner-item-container"
      style={{
        transform: `translate(-50%,-50%) rotate(${angle}deg) translateX(${dis}px)`,
        opacity: opacity,
      }}
    >
      <Link
        style={{ pointerEvents: opacity < 0.1 ? "none" : "auto" }}
        className="link-no-dec"
        to={
          selected
            ? `/projects/${title.toLowerCase().replaceAll(" ", "-")}`
            : ``
        }
      >
        <div
          onClick={() =>
            opacity > 0.1
              ? (window.location.hash = `#project-${title
                  .toLowerCase()
                  .replaceAll(" ", "-")}`)
              : ""
          }
          className="relative"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            className={"spinner-text outline-text"}
            style={{
              color: color,
              transition: "0.3s",
              transform: `scale(${scale})`,
              fontSize: fontSize,
            }}
          >
            {title}
          </p>
          <p
            className="spinner-sub-title"
            style={{
              color: selected ? "#000000FF" : "#00000000",
              transitionDelay: selected ? "0.3s" : "0s",
            }}
          >
            {subTitle}
          </p>
          <p
            className="spinner-sub-text"
            style={{
              color: selected ? "#052858FF" : "#05285800",
              transitionDelay: selected ? "0.3s" : "0s",
            }}
          >
            View Project {">"}
          </p>
        </div>
      </Link>
    </div>
  );
};

const SpinnerText: React.FC<SpinnerTextProps> = ({
  options,
  angleIncrement,
  dis,
  offsetX,
  selected,
  scrollProgress,
  fontSize,
  resting,
}) => {
  const { isMobile, isSmall } = useWinSize();
  const isTiny = isMobile || isSmall;
  return (
    <div className="sticky-spinner-container">
      <div className="spinner-overflow-hidden relative">
        {/* a container that sits in the sticky div but isn't the rotating wheel */}
        <div className="spinner-static-container">
          <ArcScrollProgress
            radius={isTiny && resting ? 4 : dis * 2}
            progress={scrollProgress}
          />
          <SpinnerImage resting={resting} img={options[selected].image} />
        </div>
        {/* create the container that actually rotates the whole wheel. */}
        <div
          className="spinner-rotating-wheel"
          style={{
            position: "absolute",
            transformOrigin: "50% 50%",
            transform: `rotate(${-selected * angleIncrement}deg)`,
            top: isTiny && resting ? `calc(35vh - 65px)` : "50vh",
            left: isTiny && resting ? `${-dis / 1.5}px` : "0px",
          }}
        >
          {options.map((val, i) => {
            return (
              <OffsetTextItem
                key={`spinner-item-${val.title}-${i}`}
                angleIncrement={angleIncrement}
                index={i}
                dis={dis}
                title={val.title}
                subTitle={val.subtitle}
                selectedIndex={selected}
                selected={selected === i}
                fontSize={fontSize}
                showNonSelected={!(resting && isTiny)}
              ></OffsetTextItem>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SpinnerText;
