import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useNavLoc = () => {
  const location = useLocation();
  const isBaseRoute = useMemo(
    () => location.pathname.toLowerCase() === "/",
    [location]
  );
  const isProjectRoute = useMemo(
    () => location.pathname.toLowerCase().includes("projects"),
    [location]
  );
  const isUnlockedThoughts = useMemo(
    () => location.pathname.toLowerCase().includes("unlocked_thoughts"),
    [location]
  );
  const isOther = useMemo(() => {
    return !isBaseRoute && !isProjectRoute && !isUnlockedThoughts;
  }, [isBaseRoute, isProjectRoute, isUnlockedThoughts]);

  return { isBaseRoute, isProjectRoute, isUnlockedThoughts, isOther };
};

export default useNavLoc;
