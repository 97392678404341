import { useMemo } from "react";
import useImageLoader from "../../Particles/hooks/useImageLoader";

const baseURL = process.env.REACT_APP_BASE_URL;
const env = process.env.REACT_ENVIRONMENT;
const selected = Math.round(Math.random() * 3) + 1;
const imageSource = env !== "DEV" ? `${baseURL}${"/images/project"}` : "";
const images = {
  images: [
    { src: `${imageSource}/color${selected}.png` },
    { src: `${imageSource}/github.svg` },
    { src: `${imageSource}/linkedin.svg` },
    { src: `${imageSource}/email.svg` },
  ],
};

//Loads in resoucres for the intro section particle stuff
const useParticleImageLoad = () => {
  const { loadedImages } = useImageLoader(images);

  const loaded = useMemo(() => {
    if (loadedImages.length > 0) {
      return true;
    }
    return false;
  }, [loadedImages]);

  return {
    particleImages: loadedImages,
    piLoaded: loaded,
    piSelected: selected,
  };
};

export default useParticleImageLoad;
