import { useContext, useEffect, useState } from "react";
import CollapsableTitle from "../../Collapsable/CollapsableTitle";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import { ProjectData } from "../types";
import useDebounce from "../../../Hooks/useDebounce";
import { DataContext } from "../../../Context/DataContext";
import { EditorContext } from "../../../Context/EditorContext";

interface EditorPostProps {}

const EditorPost: React.FC<EditorPostProps> = () => {
  const { data, loadProject } = useContext(EditorContext);
  const [name, setName] = useState(data?.title ?? "");
  const [shortName, setShortName] = useState(data?.small_title ?? "");
  const [year, setYear] = useState(data?.year ?? "");
  const [previewImage, setPreviewImage] = useState(data?.preview_image ?? "");
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const { debounce } = useDebounce(1000);
  const { fetcher } = useContext(DataContext);

  const update = async () => {
    const response = await fetcher(`/posts/${data.id}`, {
      method: "PUT",
      body: {
        title: name,
        small_title: shortName,
        year: year,
        preview_image: previewImage,
      },
    });
  };

  const updateStatus = async () => {
    setUpdatingStatus(true);
    const response = await fetcher(`/posts/status/${data.id}`, {
      method: "PUT",
      body: { status: data.status === "posted" ? "draft" : "posted" },
    });
    loadProject();
    setUpdatingStatus(false);
  };

  const handlePublishing = () => {
    updateStatus();
  };

  useEffect(() => {
    if (
      name !== data.title ||
      shortName !== data.small_title ||
      year !== data.year ||
      previewImage !== data.preview_image
    ) {
      debounce(update);
    }
  }, [name, shortName, year, previewImage]);

  return (
    <CollapsableTitle title="Post">
      <div className="editor-collapsable">
        <Input
          label="Name"
          id="editor-name"
          onChange={(val: string) => setName(val)}
          value={name}
        />
        <Input
          label="Short Name"
          id="editor-short-name"
          onChange={(val: string) => setShortName(val)}
          value={shortName}
        />
        <Input
          label="Year"
          id="editor-year"
          onChange={(val: string) => setYear(val)}
          value={year}
        />
        <Input
          label="Preview Image"
          id="editor-preview-image"
          onChange={(val: string) => setPreviewImage(val)}
          value={previewImage}
        />
        <div className="editor-status">
          <h3>Status</h3>
          <p>{data.status === "posted" ? "Posted" : "Draft"}</p>
        </div>
        <Button disabled={updatingStatus} onClick={handlePublishing}>
          {data?.status === "posted" ? "Unpost" : "Publish"}
        </Button>
        <Button onClick={handlePublishing}>Save as Draft</Button>
      </div>
    </CollapsableTitle>
  );
};

export default EditorPost;
