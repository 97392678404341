import { MComponent } from "../../CMS/types";
import Carousal from "../Carousal/Carousal";
import ProjectContainer from "../ProjectContainer/ProjectContainer";

interface ProjectProps {
  title: string;
  year: string;
  data: MComponent[];
  githubLink?: string;
  projectLink?: string;
  fileLinks?: any;
  colSizes: string[];
  noPadding?: number[];
  //a varaible meant for mcomponent mostly for editing, it allows us to know which level and item we are on
  item?: number[];
}

const Project: React.FC<ProjectProps> = ({
  data,
  title,
  year,
  projectLink,
  githubLink,
  fileLinks,
  colSizes,
  noPadding,
  item,
}) => {
  return (
    <div className="">
      <div className="space"></div>
      <div className="flex-center">
        <div className="flex-center project-title-container">
          <h2>{title}</h2>
          <h5>{year}</h5>
        </div>
      </div>

      <div className="space"></div>
      <ProjectContainer
        colSizes={colSizes}
        noPaddingIndices={noPadding}
        data={data}
        githubLink={githubLink}
        projectLink={projectLink}
        fileLinks={fileLinks}
        item={item}
      />
      <div className="space"></div>
    </div>
  );
};

export default Project;
