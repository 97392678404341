import { useEffect, useState } from "react";
import {
  ChipInput,
  FormInputs,
  SelectInput,
  SubChipInput,
  TextInput,
} from "./types";
import Input from "../Input/Input";
import SubChips, { SubChip } from "../Chips/SubChips";
import Chips from "../Chips/Chips";
import Select from "../Input/Select/Select";

interface FormProps {
  items: FormInputs;
  onChange: (vals: { [key: string]: any }) => void;
}

const Form: React.FC<FormProps> = ({ items, onChange }) => {
  const [data, setData] = useState<any>({});

  const handleChange = (val: any, key: string) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    let newVal = val;
    if (items[key]?.format) {
      newVal = items[key].format?.(newVal) ?? val;
    }
    dataCopy[key] = newVal;
    setData(dataCopy);
    onChange(dataCopy);
  };

  //set up the initial values
  useEffect(() => {
    console.log("loading initial");
    let dataCopy = JSON.parse(JSON.stringify(data));
    const keys = Object.keys(items);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let initalValue = items[key].initialValue;
      if (items[key].type === "chips" && !initalValue) {
        initalValue = [];
      } else if (items[key].type === "subChips" && !initalValue) {
        if ((items[key] as SubChipInput).subchipType === "list") {
          initalValue = [];
        } else {
          initalValue = {};
        }
      } else if (items[key].type === "text" && !initalValue) {
        initalValue = "";
      } else if (items[key].type === "select" && !initalValue) {
        initalValue = (items[key] as SelectInput).options?.[0]?.value ?? "";
      }
      dataCopy[key] = initalValue;
    }
    setData(dataCopy);
  }, [items]);

  return (
    <>
      {Object.keys(items).map((key, i) => {
        const item = items[key];
        if (item.type === "subChips") {
          const subChipsProps = item as SubChipInput;
          return (
            <SubChips
              key={`form-input-subchip-${key}`}
              id={key}
              subChips={data[key] as SubChip}
              onChange={(vals: SubChip) => handleChange(vals, key)}
              {...subChipsProps}
              type={subChipsProps.subchipType}
            />
          );
        } else if (item.type === "chips") {
          const chipsProps = item as ChipInput;
          return (
            <Chips
              key={`form-input-chip-${key}`}
              id={key}
              chips={data[key]}
              onChange={(vals: string[]) => handleChange(vals, key)}
              {...chipsProps}
            />
          );
        } else if (item.type === "select") {
          console.log(data[key]);
          const selectProps = item as SelectInput;
          return (
            <Select
              onChange={(val: string) => handleChange(val, key)}
              value={data[key]}
              {...selectProps}
            />
          );
        } else if (item.type === "text") {
          const inputProps = item as TextInput;
          return (
            <Input
              key={`form-input-text-${key}`}
              id={key}
              value={data[key]}
              onChange={(val: string) => handleChange(val, key)}
              {...inputProps}
            />
          );
        }
      })}
    </>
  );
};

export default Form;
