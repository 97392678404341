import { CSSProperties } from "react";

interface ButtonProps {
  children?: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  className,
  style,
  disabled = false,
}) => {
  return (
    <button
      style={style}
      onClick={() => (disabled ? null : onClick?.())}
      className={`button-container ${disabled ? "button-disabled" : ""} ${
        className ?? ""
      }`}
    >
      {children}
    </button>
  );
};

export default Button;
