import { useMemo, useState } from "react";

interface UseDragDropList {
  onDrag: (indx: number) => void;
  onDragOver: (e: React.DragEvent<HTMLElement>, indx: number) => void;
  onDragLeave: () => void;
  onDrop: (i: number) => void;
  outList: any[];
}

interface UseDragDropListProps {
  list: any[];
  onChange: (list: any[]) => void;
  useDragNDrop?: boolean;
}

const useDragDropList: (props: UseDragDropListProps) => UseDragDropList = ({
  list,
  onChange,
  useDragNDrop = true,
}) => {
  const [dragging, setDragging] = useState<null | number>(null);
  const [dragOver, setDragOver] = useState<null | number>(null);

  const onDrag = (indx: number) => {
    if (indx !== dragging) setDragging(indx);
  };

  const onDragOver = (e: React.DragEvent<HTMLElement>, indx: number) => {
    //make sure to prevent default or else the drop method won't really work
    e.preventDefault();
    if (dragOver !== indx) {
      setDragOver(indx);
    }
  };

  const onDragLeave = () => {
    //when the user leaves an area just assign null
    setDragOver(null);
  };

  //move the selected item to where it is being dragged to. If there is no selected or item being dragged then just return the regular list
  const getSortedList = (i?: number | null) => {
    if (
      dragging !== i &&
      dragging !== null &&
      dragging !== undefined &&
      i !== null &&
      i !== undefined &&
      useDragNDrop
    ) {
      let items = list;
      const item = list[dragging];
      items = [
        ...items.slice(0, dragging),
        ...(items.slice(dragging + 1) ?? []),
      ];
      items = [...items.slice(0, i), item, ...(items.slice(i) ?? [])];
      return items;
    }
    return list;
  };

  //only when the list is updated or the dragging or drag over send out a "preview" list of what the list would look like if the drag and drop were applied.
  const outList = useMemo(
    () => getSortedList(dragOver),
    [dragOver, dragging, list]
  );

  const onDrop = (i: number) => {
    if (useDragNDrop) {
      onChange(getSortedList(i));
    }
  };

  return { onDrag, onDragOver, onDrop, outList, onDragLeave };
};

export default useDragDropList;
