interface ExperienceBlockProps {
  title: string;
  position: string;
  startDate: string;
  endDate: string;
  description: string;
  projectLink: string;
}

export const ExperienceBlock: React.FC<ExperienceBlockProps> = ({
  title,
  position,
  startDate,
  endDate,
  description,
}) => {
  return (
    <div className="experience-block-container">
      <h5 className="experience-date">
        {startDate} - {endDate}
      </h5>
      <h1 className="experience-title">{title}</h1>
      <h4 className="experience-position">{position}</h4>
      <p className="experience-description">{description}</p>
    </div>
  );
};
