import { createRef, useContext, useEffect, useState } from "react";
import { HomePageContext } from "../../../../Context/HomePageContext";
import { PCContext } from "../../../../Context/PCContext";
import useWinSize from "../../../../Hooks/useWinSize";
import { DataContext } from "../../../../Context/DataContext";
import ContactModal from "../../../../Components/Modal/Modals/ContactModal";

interface ContactSectionProps {}
const ContactSection: React.FC<ContactSectionProps> = () => {
  const contactSectionRef = createRef<HTMLDivElement>();
  const { contactPageOffsetTop } = useContext(HomePageContext);
  const { pSupported } = useContext(PCContext);
  const { particleImages } = useContext(DataContext);
  const { isMobile, isTablet, isDesktop } = useWinSize();
  const [formOpened, setFormOpened] = useState(false);

  useEffect(() => {
    const updateSizing = () => {
      if (contactSectionRef.current && contactPageOffsetTop) {
        contactPageOffsetTop.current = contactSectionRef.current.offsetTop;
      }
    };
    updateSizing();
    window.addEventListener("resize", updateSizing);
    return () => window.removeEventListener("resize", updateSizing);
  }, [contactSectionRef]);

  const onEmailClick = () => {
    setFormOpened(true);
  };

  const onGithubClick = () => {
    window.open("https://github.com/tbpatj");
  };

  const onLinkedInClick = () => {
    window.open("https://www.linkedin.com/in/timothy-luebke-252598224/");
  };

  return (
    <div
      id="contact-section"
      ref={contactSectionRef}
      className="contact-section-container"
      style={!pSupported ? { zIndex: 0 } : {}}
    >
      <ContactModal opened={formOpened} onClose={() => setFormOpened(false)} />
      <div className="contact-tab-title">
        <h3 className="contact-title">
          {isMobile || isTablet ? "Contact" : "Contact Me"}
        </h3>
      </div>
      {!pSupported && (
        <div className="contact-svg-container">
          <div className="contact-horizontal-container">
            <>
              {isDesktop && (
                <img
                  onClick={onLinkedInClick}
                  className="contact-svg"
                  src={particleImages?.[2]?.image?.src ?? ""}
                ></img>
              )}
              {isDesktop && (
                <img
                  onClick={onEmailClick}
                  className="contact-svg"
                  src={particleImages?.[3]?.image?.src ?? ""}
                ></img>
              )}

              <img
                onClick={onGithubClick}
                className="contact-svg"
                src={particleImages?.[1]?.image?.src ?? ""}
              ></img>
            </>
          </div>
          <div className="contact-horizontal-container">
            {!isDesktop && (
              <img
                onClick={onEmailClick}
                className="contact-svg"
                src={particleImages?.[3]?.image?.src ?? ""}
              ></img>
            )}
            {!isDesktop && (
              <img
                onClick={onLinkedInClick}
                className="contact-svg"
                src={particleImages?.[2]?.image?.src ?? ""}
              ></img>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactSection;
