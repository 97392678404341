import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../Context/DataContext";
import useNavLoc from "../../Hooks/Nav/useNavLoc";

const MobileNav: React.FC = () => {
  const [navOpened, setNavOpened] = useState(false);
  const { isBaseRoute, isProjectRoute, isUnlockedThoughts } = useNavLoc();
  const opened = navOpened ? "opened" : "";
  const closeNav = { onClick: () => setNavOpened(false) };
  const { permissions } = useContext(DataContext);
  return (
    <>
      <div className="relative">
        <div className="nav-container">
          <span></span>
          <div className="nav-header">
            {isBaseRoute
              ? "Home"
              : isProjectRoute
              ? "Projects"
              : isUnlockedThoughts
              ? "Thoughts"
              : "Not Found"}
          </div>
          <span></span>
        </div>
        <div className={`small-nav-container ${opened}`}>
          <div className="nav-item-container">
            <Link className="link-no-dec nav-item" to={"/"} {...closeNav}>
              Home
            </Link>
            {isBaseRoute && (
              <>
                <div
                  className="link-no-dec nav-item"
                  onClick={() => {
                    window.location.hash = "#project-section";
                  }}
                >
                  Projects
                </div>
                <div
                  className="link-no-dec nav-item"
                  onClick={() => {
                    window.location.hash = "#about-section";
                  }}
                >
                  About
                </div>
                <div
                  className="link-no-dec nav-item"
                  onClick={() => {
                    window.location.hash = "#experience-section";
                  }}
                >
                  Experience
                </div>
                <div
                  className="link-no-dec nav-item"
                  onClick={() => {
                    window.location.hash = "#contact-section";
                  }}
                >
                  Contact
                </div>
              </>
            )}
            {permissions.post_creating &&
              permissions.post_deletion &&
              permissions.post_editing && (
                <Link
                  className="link-no-dec nav-item"
                  to={"/admin/projects"}
                  {...closeNav}
                >
                  Admin Projects
                </Link>
              )}
            {permissions.t_rights && (
              <Link
                className="link-no-dec nav-item"
                to={"/unlocked_thoughts"}
                {...closeNav}
              >
                Thoughts
              </Link>
            )}
            {permissions.j_rights && (
              <Link
                className="link-no-dec nav-item"
                to="/journal_entry"
                {...closeNav}
              >
                Journal Entry
              </Link>
            )}
          </div>
          <div
            className="hamburger-container"
            onClick={() => setNavOpened(!navOpened)}
          >
            <div className={`hamburger ${opened}`}></div>
            <div className={`hamburger hidden ${opened}`}></div>
            <div className={`hamburger flipped ${opened}`}></div>
          </div>
        </div>
        <div
          className={`nav-overlay ${opened}`}
          onClick={() => setNavOpened(false)}
        ></div>
      </div>
      <div style={{ height: "45px" }}></div>
    </>
  );
};

export default MobileNav;
