import { useContext, useMemo } from "react";
import ScrollTransition from "../Components/Animations/ScrollTransition";
import Project from "../Components/Project/Project";
import {
  MComponent,
  MComponentAnchor,
  MComponentImg,
  MComponentProjectContainer,
  MComponentResourcesCarousal,
  MComponentVid,
} from "./types";
import { EditorContext } from "../Context/EditorContext";
import ResourcesCarousal from "../Components/Carousal/ResourcesCarousal";

interface Props {
  component: MComponent;
  layer?: number;
  item?: number[];
}

interface MCProps {
  components: MComponent | MComponent[];
  layer?: number;
  item?: number[];
}
/** Modifiable component, is capable of taking information and spewing out the html needed from passed data. Very useful for making a sort of blog */
const MC: React.FC<MCProps> = ({ components, layer, item }) => {
  if (Array.isArray(components)) {
    return (
      <>
        {components.map((component: MComponent, i: number) => (
          <SingleMC
            key={`m-component-${i}-${component.tag}-${component.class ?? ""}-${
              layer ?? 0
            }`}
            component={component}
            layer={layer ?? 0}
            item={[...(item ?? []), i]}
          />
        ))}
      </>
    );
  } else {
    return (
      <>
        <SingleMC component={components} layer={layer ?? 0} item={item} />
      </>
    );
  }
};

const SingleMC: React.FC<Props> = ({ component: c, layer, item }) => {
  const { updateSelected, loadedData } = useContext(EditorContext);
  const cProps = useMemo(() => {
    if (loadedData) {
      return {
        onClick: (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          if (item) {
            updateSelected?.(item);
          }
        },
        style: c?.style,
        className: c?.class,
      };
    }
    return {
      style: c?.style,
      className: c?.class,
    };
  }, [c.style, c.class, loadedData]);
  const contentType = typeof c?.c;
  const isDisplayableContent =
    contentType === "string" || contentType === "number";
  const content = isDisplayableContent ? (
    (c?.c as string | number)
  ) : !c?.c ? (
    ""
  ) : (
    <>
      <MC
        layer={(layer ?? 0) + 1}
        components={c.c as MComponent | MComponent[]}
        item={[...(item ?? [])]}
      ></MC>
    </>
  );
  return (
    <>
      {c.tag === "div" && <div {...cProps}>{content}</div>}
      {c.tag === "p" && <p {...cProps}>{content}</p>}
      {c.tag === "h1" && <h1 {...cProps}>{content}</h1>}
      {c.tag === "h2" && <h2 {...cProps}>{content}</h2>}
      {c.tag === "h3" && <h3 {...cProps}>{content}</h3>}
      {c.tag === "h4" && <h4 {...cProps}>{content}</h4>}
      {c.tag === "h5" && <h5 {...cProps}>{content}</h5>}
      {c.tag === "h6" && <h6 {...cProps}>{content}</h6>}
      {c.tag === "img" && (
        <img
          {...cProps}
          src={(c as MComponentImg)?.src}
          alt={(c as MComponentImg)?.alt}
        ></img>
      )}
      {c.tag === "video" && (
        <video
          {...cProps}
          controls={(c as MComponentVid)?.controls === "true" ? true : false}
          autoPlay={(c as MComponentVid)?.autoplay === "true" ? true : false}
          playsInline={
            (c as MComponentVid)?.playsinline === "true" ? true : false
          }
          loop={(c as MComponentVid)?.loop === "true" ? true : false}
          src={(c as MComponentVid)?.src}
          poster={(c as MComponentVid)?.poster ?? undefined}
        ></video>
      )}
      {c.tag === "a" && (
        <a {...cProps} href={(c as MComponentAnchor)?.href}>
          {content}
        </a>
      )}
      {c.tag === "ScrollTransition" && (
        <ScrollTransition>{content}</ScrollTransition>
      )}
      {c.tag === "ProjectContainer" && Array.isArray(c.c) && (
        <Project
          data={c?.c ?? []}
          title={(c as MComponentProjectContainer)?.title ?? ""}
          year={(c as MComponentProjectContainer)?.year ?? ""}
          projectLink={(c as MComponentProjectContainer)?.projectLink}
          githubLink={(c as MComponentProjectContainer)?.githubLink}
          fileLinks={(c as MComponentProjectContainer)?.fileLinks}
          colSizes={(c as MComponentProjectContainer)?.colSizes ?? ["100%"]}
          noPadding={(c as MComponentProjectContainer)?.noPadding ?? []}
          item={item}
        />
      )}
      {c.tag === "ResourcesCarousal" && (
        <ResourcesCarousal
          {...cProps}
          carousalData={(c as MComponentResourcesCarousal)?.carousalData}
          tabPosition={(c as MComponentResourcesCarousal)?.tabPosition}
        />
      )}
    </>
  );
};

export default MC;
