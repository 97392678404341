import { createContext, useRef, useState } from "react";

interface HomePageProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

interface HomePageProps {
  contactPageOffsetTop: React.MutableRefObject<number> | undefined;
}

export const HomePageContext = createContext<HomePageProps>({
  contactPageOffsetTop: undefined,
});

export const HomePageProvider: React.FC<HomePageProviderProps> = ({
  children,
}) => {
  const pageOffsetRef = useRef(4000);
  const value: HomePageProps = {
    contactPageOffsetTop: pageOffsetRef,
  };

  return (
    <HomePageContext.Provider value={value}>
      {children}
    </HomePageContext.Provider>
  );
};
