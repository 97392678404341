import { CSSProperties, useRef, useState } from "react";
import useDebounce from "../../Hooks/useDebounce";

interface MyVideoProps {
  src: string;
  className?: string;
  style?: CSSProperties;
}

const MyVideo: React.FC<MyVideoProps> = ({ src, className, style }) => {
  const [showControls, setShowControls] = useState(false);
  const { debounce } = useDebounce(1000);

  const handleMouseMove = () => {
    if (showControls === false) {
      setShowControls(true);
    }
    debounce(() => setShowControls(false));
  };
  return (
    <video
      className={className}
      style={style}
      controls={showControls}
      onMouseMove={handleMouseMove}
      src={src}
    />
  );
};

export default MyVideo;
