import UsePagination from "../../Hooks/UsePagination";

interface Pagination {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  siblingCount: number;
  id: string;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<Pagination> = ({
  currentPage,
  pageSize,
  totalCount,
  siblingCount,
  onPageChange,
  id,
}) => {
  const range = UsePagination({
    currentPage,
    pageSize,
    totalCount,
    siblingCount,
  });

  return (
    <div id={id} className="pagination-container">
      {range &&
        range?.map((val, i) => (
          <div
            key={`${id}-pagination-buttom-${i}-${val}`}
            className={`pagination-button ${
              currentPage === val ? "selected" : ""
            }`}
            onClick={() => (typeof val !== "string" ? onPageChange(val) : null)}
          >
            {val}
          </div>
        ))}
    </div>
  );
};

export default Pagination;
