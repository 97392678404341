import { createContext, useMemo, useState } from "react";
import { MComponent } from "../CMS/types";
import { useParams } from "react-router-dom";
import { initialContentData, initialDataContent } from "../Resources/Editor";
import useProjectData, {
  LoadProjectFunc,
  SaveProjectContentFunc,
  UpdateCSSFunc,
  UpdateContentFunc,
} from "../Hooks/EditorContext/useProjectData";
import { ProjectData } from "../Components/Editor/types";
import useSelectedComponent, {
  UpdateSelectedFunc,
} from "../Hooks/EditorContext/useSelectedComponent";

interface EditorContextProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

interface EditorProps {
  data: ProjectData;
  content: MComponent;
  loadedData: boolean;
  selected: number[];
  updateSelected: UpdateSelectedFunc;
  updateContent: UpdateContentFunc;
  loadProject: LoadProjectFunc;
  saveProjectContent: SaveProjectContentFunc;
  updateCSS: UpdateCSSFunc;
}

export const EditorContext = createContext<EditorProps>({
  data: initialDataContent,
  content: initialContentData,
  loadedData: false,
  selected: [],
  updateSelected: () => null,
  loadProject: () => null,
  saveProjectContent: () => null,
  updateContent: () => null,
  updateCSS: () => null,
});

export const EditorProvider: React.FC<EditorContextProviderProps> = ({
  children,
}) => {
  const { id } = useParams();
  const {
    data,
    content,
    loadedData,
    loadProject,
    saveProjectContent,
    updateContent,
    updateCSS,
  } = useProjectData({
    id,
  });

  const { selected, updateSelected } = useSelectedComponent({});

  const value: EditorProps = useMemo(() => {
    return {
      data: data ?? initialDataContent,
      content,
      loadedData,
      selected,
      updateSelected,
      updateContent,
      loadProject,
      saveProjectContent,
      updateCSS,
    };
  }, [
    data,
    content,
    updateContent,
    loadProject,
    saveProjectContent,
    selected,
    updateSelected,
    updateCSS,
  ]);

  return (
    <EditorContext.Provider value={value}>{children}</EditorContext.Provider>
  );
};
