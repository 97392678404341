import useWinSize from "../../Hooks/useWinSize";
import OutlineContainer from "../OutlineContainer/OutlineContainer";
import { ReactComponent as ExternalLink } from "../../SVGs/external-link.svg";
import { ReactComponent as GithubIcon } from "../../SVGs/github.svg";
import { CSSProperties, useMemo } from "react";
import { MComponent } from "../../CMS/types";
import MC from "../../CMS/MComponent";
import DownloadFiles from "./DownloadFiles";

interface ProjectContainerProps {
  style?: CSSProperties;
  className?: string;
  colSizes?: string[];
  noPaddingIndices?: number[];
  noRelativeIndices?: number[];
  projectLink?: string;
  data: MComponent | MComponent[];
  githubLink?: string;
  fileLinks?: { [key: string]: string }[];
  //a varaible for the MComponent function that helps us know where we are located
  item?: number[];
}

const ProjectContainer: React.FC<ProjectContainerProps> = ({
  style,
  className,
  colSizes,
  noPaddingIndices,
  noRelativeIndices,
  data,
  githubLink,
  fileLinks,
  projectLink,
  item,
}) => {
  const { isDesktop } = useWinSize();
  const hasSideIcon = useMemo(
    () => githubLink || fileLinks,
    [githubLink, fileLinks]
  );
  const newColSizes = useMemo(() => {
    const sizes = ["100%", ...(colSizes ?? []), "100%"];
    return sizes;
  }, [colSizes]);

  const hasFiles = useMemo(() => {
    if (fileLinks && fileLinks.length > 0) {
      return true;
    }
    return false;
  }, [fileLinks]);

  const noRelative =
    isDesktop && hasSideIcon
      ? [newColSizes.length - 1, ...(noRelativeIndices ?? [])]
      : noRelativeIndices;
  return (
    <div className="outline-container-base">
      <OutlineContainer
        className={`project-container ${className ?? ""}`}
        style={style}
        colSizes={newColSizes}
        noPaddingIndices={noPaddingIndices}
        noRelativeIndices={noRelative}
      >
        <div>
          {!projectLink && <div className="space"></div>}
          {projectLink && (
            <a className="link-no-dec" href={projectLink}>
              <div className="project-link-container ">
                <p className="project-link ">Open Project</p>
                <ExternalLink stroke="#052858"></ExternalLink>
              </div>
            </a>
          )}
        </div>
        {Array.isArray(data) ? (
          data.map((val, i) => {
            return (
              <MC
                key={`project-sub-component-${i}`}
                components={val}
                item={[...(item ?? []), i]}
              ></MC>
            );
          })
        ) : (
          <MC components={data} item={[...(item ?? []), 0]} />
        )}
        {hasSideIcon && (
          <div className="project-icon-containers">
            {/* @ts-ignore */}
            {hasFiles && <DownloadFiles fileLinks={fileLinks} />}
            {githubLink && (
              <a href={githubLink}>
                <GithubIcon fill="#052858" className="pointer-cursor" />
              </a>
            )}
          </div>
        )}
      </OutlineContainer>
    </div>
  );
};

export default ProjectContainer;
