import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DataContext } from "../../Context/DataContext";
import { ReactComponent as EditIcon } from "../../SVGs/edit-icon.svg";
import CreateProjectModal from "../../Components/Modal/Modals/CreateProject";
import { Link } from "react-router-dom";
import DragOrderList from "../../Components/Input/DragOrderList";
import CollapsableTitle from "../../Components/Collapsable/CollapsableTitle";
import DeleteConfirmModal from "../../Components/Modal/Modals/DeleteConfirmModal";

const AdminProjectsPage: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const { fetcher } = useContext(DataContext);
  //deletion variables
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [toDelete, setToDelete] = useState<any | undefined>();
  const [deleteError, setDeleteError] = useState("");

  const getAllPosts = async () => {
    const response = await fetcher("/posts/all");
    if (response?.data) setData(response.data);
  };

  const updatePriority = async (list: any[]) => {
    const updateData = list.map((val, index) => {
      return {
        priority: index + 1,
        id: val.id,
        title: val.title,
      };
    });
    const response = await fetcher("/posts/updatePriority", {
      method: "POST",
      body: updateData,
    });
    if (response?.data) setData(response.data);
  };

  useEffect(() => {
    getAllPosts();
  }, []);

  const posted = useMemo(
    () => data.filter((val: any) => val.status === "posted"),
    [data]
  );
  const drafts = useMemo(
    () => data.filter((val: any) => val.status === "draft"),
    [data]
  );

  const deleteProject = async () => {
    if (toDelete) {
      const response = await fetcher("/posts/delete", {
        method: "DELETE",
        body: { id: toDelete.id, content_id: toDelete.content_id },
      });
      if (response?.data === "deleted") {
        setData(data.filter((val: any) => val.id !== toDelete.id));
        setDeleteModalOpened(false);
        setDeleteError("");
      } else {
        setDeleteError("Failed to delete");
      }
    }
  };

  const deleteModalClose = useCallback(() => {
    setToDelete(undefined);
    setDeleteModalOpened(false);
    setDeleteError("");
  }, [setDeleteModalOpened, setToDelete]);

  const renderCB = useCallback(
    (val: any) => {
      const onDeleteClick = (val: any) => {
        setDeleteModalOpened(true);
        setToDelete(val);
      };
      return (
        <div className="admin-project-item">
          <div className="admin-project-item-contanier">
            <Link
              className="admin-project-item-contanier"
              to={`/admin/projects/${val.content_id}`}
            >
              <EditIcon style={{ width: "16px" }} />
            </Link>
            <span>{val?.title}</span>
          </div>
          <div onClick={() => onDeleteClick(val)}>delete</div>
        </div>
      );
    },
    [setDeleteModalOpened, setToDelete]
  );

  return (
    <div style={{ padding: "30px" }}>
      <h1 className="admin-project-header">
        Projects
        <CreateProjectModal />
      </h1>
      <div className="collapsable-seperator"></div>
      <div className="admin-project-container">
        <CollapsableTitle
          initiallyOpened={true}
          useSeperator={false}
          titleClassName="admin-project-section-title"
          title="Posted"
        >
          <div className="admin-project-items-container">
            <DragOrderList
              list={posted}
              id="order-list-posted"
              onChange={updatePriority}
              renderCB={renderCB}
            />
          </div>
        </CollapsableTitle>
        <CollapsableTitle
          initiallyOpened={true}
          useSeperator={false}
          titleClassName="admin-project-section-title"
          title="Drafts"
        >
          <div className="admin-project-items-container">
            <DragOrderList
              list={drafts}
              id="order-list-drafts"
              onChange={() => null}
              renderCB={renderCB}
              dragDisabled={true}
            />
          </div>
        </CollapsableTitle>
      </div>
      <DeleteConfirmModal
        open={deleteModalOpened}
        onCancel={deleteModalClose}
        onConfirm={deleteProject}
      />
    </div>
  );
};

export default AdminProjectsPage;
