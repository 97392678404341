import { CSSProperties, useEffect, useRef, useState } from "react";
import { SelectSpinnerOptions } from "./type";
import { useNavigate } from "react-router-dom";
import SpinnerText from "./SpinnerText";
import useDebounce from "../../Hooks/useDebounce";

interface SelectSpinner2Props {
  options: SelectSpinnerOptions[];
  angleIncrement?: number;
  dis?: number;
  fontSize?: string;
  className?: string;
  style?: CSSProperties;
  offsetX?: number;
  onSelect?: (val: number) => void;
  id?: string;
}
const angleIncrementDefault = 20;

const SelectSpinner2: React.FC<SelectSpinner2Props> = ({
  angleIncrement = angleIncrementDefault,
  dis = 30,
  offsetX,
  fontSize,
  options,
  onSelect,
  className,
  style,
  id,
}) => {
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);
  const selected = useRef<number>(0);
  const [resting, setResting] = useState(false);
  const { debounce } = useDebounce(1000);

  const handleScroll = (e: Event) => {
    if (scrollRef) {
      //some code to eanble us to tell if the user has stopped scrolling

      addScrollSnap();
      // get how far away the scroll position is from the current ref's position
      const fromTop =
        window.scrollY +
        window.innerHeight / 2 -
        (scrollRef.current?.offsetTop ?? 0);
      const amountToScroll = scrollRef.current?.clientHeight ?? 0;

      //calculate what index should be selected based on the percentage of how far we have scrolled to the end
      const scrollDis = Math.max(
        Math.min(
          Math.floor((fromTop / amountToScroll) * options.length),
          options.length - 1
        ),
        0
      );
      if (
        (scrollDis < options.length - 1 && scrollDis > 0) ||
        selected.current !== scrollDis
      ) {
        setResting(false);
        debounce(() => setResting(true));
      }
      if (scrollDis !== selected.current) {
        selected.current = scrollDis;
        onSelect?.(scrollDis);
      }
    }
  };

  const addScrollSnap = () => {
    const fromTop =
      window.scrollY +
      window.innerHeight / 4 -
      (scrollRef.current?.offsetTop ?? 500);
    const height = scrollRef.current?.clientHeight ?? 500;
    const fromEnd =
      window.scrollY +
      3 * (window.innerHeight / 4) -
      ((scrollRef.current?.offsetTop ?? 500) + height);
    //check if the objects are in view then change the scrollsnap type so that it clicks between items
    if (fromTop > -50 && fromEnd < 30) {
      const test = document.getElementsByTagName("html");
      const html = test[0];
      html.style.scrollSnapType = "y proximity";
    } else {
      const test = document.getElementsByTagName("html");
      const html = test[0];
      html.style.scrollSnapType = "none";
    }
  };

  useEffect(() => {
    //set the scroll type to snapping
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    addScrollSnap();
  }, [scrollRef]);

  // const handleClick = (indx: number) => {
  //   if (selected.current === indx) {
  //     navigate(
  //       `/projects/${options?.[indx]?.title.toLowerCase().replaceAll(" ", "-")}`
  //     );
  //   }
  // };

  return (
    <>
      <div id={id}>
        {/* the scroll wheel container */}
        <SpinnerText
          resting={resting}
          options={options}
          scrollProgress={(selected.current + 0.2) / (options.length - 1)}
          dis={dis}
          offsetX={offsetX ?? 0}
          angleIncrement={angleIncrement}
          selected={selected.current}
          fontSize={fontSize ?? "25px"}
        />
        {/* these are placement holders they help the viewport snap to the correct position */}
        <div className="text-spinner-scroller" ref={scrollRef}>
          {options.map((val, i) => (
            <div key={`scroll-controller-item-${i}-${val.title}`}>
              <div
                key={`scroll-id-tag-item-${i}-${val.title}`}
                className="spinner-anchor-id-item"
                id={`project-${val.title.toLowerCase().replaceAll(" ", "-")}`}
              ></div>
              <div className="text-spinner-css-snap">{val.title}</div>
            </div>
          ))}
        </div>
        <div style={{ height: "400px" }}></div>
      </div>
    </>
  );
};

export default SelectSpinner2;
