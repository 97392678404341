import { CSSProperties, useContext, useMemo, useState } from "react";
import Modal from "../Modal";
import Form from "../../Form/Form";
import Button from "../../Button/Button";
import { FormInputs } from "../../Form/types";
import { DataContext } from "../../../Context/DataContext";
import { MComponent } from "../../../CMS/types";
import { useNavigate } from "react-router-dom";
import { carousalTemplate } from "../../../Templates/CarousalTemplate";

const ls: CSSProperties = { fontWeight: 500 };

interface ProjectFormProps {
  onSubmit: (data: FormProps) => void;
}

interface FormProps {
  title: string;
  shortTitle: string;
  year: string;
}

const ProjectForm: React.FC<ProjectFormProps> = ({ onSubmit }) => {
  const [formProps, setFormProps] = useState<FormProps>({
    title: "",
    shortTitle: "",
    year: "",
  });

  const handleSubmit = () => {
    onSubmit(formProps);
  };

  const isDisabled = useMemo(() => {
    return !(formProps.title && formProps.shortTitle && formProps.year);
  }, [formProps]);

  const items: FormInputs = useMemo(() => {
    return {
      title: {
        initialValue: "",
        type: "text",
        label: "Title",
        labelStyle: ls,
      },
      shortTitle: {
        type: "text",
        label: "Short Title",
        labelStyle: ls,
      },
      year: {
        type: "text",
        label: "Year",
        labelStyle: ls,
      },
    };
  }, []);

  return (
    <>
      <Form
        onChange={(val: any) => {
          setFormProps(val);
        }}
        items={items}
      />
      <Button disabled={isDisabled} onClick={handleSubmit}>
        Create
      </Button>
    </>
  );
};

interface CreateProjectModalProps {}

const CreateProjectModal: React.FC<CreateProjectModalProps> = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const navigate = useNavigate();
  const { fetcher } = useContext(DataContext);
  const handleSubmit = async (data: FormProps) => {
    try {
      const response = await fetcher("/posts/create", {
        method: "POST",
        body: {
          title: data.title,
          small_title: data.shortTitle,
          year: data.year,
          content: carousalTemplate,
        },
      });
      if (response && response?.data?.id) {
        console.log("created", response);
        navigate(`/admin/projects/${response?.data?.id}`);
      }
    } catch (err) {}
  };
  return (
    <>
      <Button
        style={{ padding: "0px 6px" }}
        onClick={() => setModalOpened(true)}
      >
        +
      </Button>
      <Modal
        opened={modalOpened}
        title="New Project"
        onClose={() => setModalOpened(false)}
      >
        <div className="new-project-modal-container">
          <ProjectForm onSubmit={(data) => handleSubmit(data)} />
        </div>
      </Modal>
    </>
  );
};

export default CreateProjectModal;
