import React, { useEffect, useState } from "react";

interface LanguageSectionProps {}

const languages = [
  "JS",
  "TS",
  "Python",
  "C++",
  "C#",
  ".NET",
  "Java",
  "React",
  "GraphQL",
  "SQL",
  "PostgresSQL",
];

const LanguageSection: React.FC<LanguageSectionProps> = ({}) => {
  return (
    <div className="languages-section-container">
      <div className="language-banner">
        {languages.map((v, i) => {
          return (
            <div key={`language-banner-1-${i}-${v}`} className="language-item">
              {v.toUpperCase()}
            </div>
          );
        })}
      </div>
      <div className="language-banner" style={{ animationDelay: "-30s" }}>
        {languages.map((v, i) => {
          return (
            <div key={`language-banner-2-${i}-${v}`} className="language-item">
              {v.toUpperCase()}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LanguageSection;
