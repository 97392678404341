import { useContext, useState } from "react";
import Input from "../../Components/Input/Input";
import Button from "../../Components/Button/Button";
import { DataContext } from "../../Context/DataContext";
import Select from "../../Components/Input/Select/Select";

const isDateValid = (date: string) => {
  const test = Date.parse(date);
  if (isNaN(test)) return false;
  return true;
};

const processEntries = (text: string) => {
  let currentDate = new Date();
  const entries = [];
  const lines = text?.split("\n");
  let justSet = false;
  //go through all of the lines in the text file
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    //if the line is a valid date then we will adjust the current date to be the date we find
    if (isDateValid(line.trim())) {
      justSet = true;
      currentDate = new Date(Date.parse(line.trim()));
      //make sure that the line isn't just an empty line
    } else if (line.trim() !== "") {
      //if we just recently reset the date (the lines above) then don't move the date forward as it's already the current date
      if (!justSet) {
        //move the date forward 24 hours
        currentDate = new Date(
          new Date(currentDate).getTime() + 60 * 60 * 24 * 1000
        );
      } else {
        justSet = false;
      }
      //add the entry into the found entries
      entries.push({ entry: line.trim(), date: new Date(currentDate) });
    }
  }
  return entries;
};

const processExperimental = (text: string) => {
  let currentDate = new Date();
  const entries = [];
  const lines = text?.split("\n");
  let entry = "";
  let justSet = false;
  //go through all of the lines in the text file
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    //if the line is a valid date then we will adjust the current date to be the date we find
    if (isDateValid(line.trim())) {
      if (entry !== "")
        entries.push({ entry: entry, date: new Date(currentDate) });
      justSet = true;
      currentDate = new Date(Date.parse(line.trim()));
      entry = "";
      //make sure that the line isn't just an empty line
    } else {
      entry += line + "\n";
    }
  }
  if (entry != "") {
    entries.push({ entry: entry, date: new Date(currentDate) });
  }
  return entries;
};

const JournalEntryPage: React.FC = () => {
  //   const [search, setSearch] = useState("");
  const { fetcher } = useContext(DataContext);
  const [text, setText] = useState("");
  const [type, setType] = useState<"journal" | "spiritual" | "thought">(
    "journal"
  );
  const [experimental, setExperimental] = useState(false);
  const [foundEntries, setFoundEntries] = useState<
    { entry: string; date: Date }[]
  >([]);

  const onProcess = () => {
    console.log(experimental);
    const entries = experimental
      ? processExperimental(text)
      : processEntries(text);
    setFoundEntries(entries);
  };

  const onSubmitEntries = async () => {
    const response = await fetcher(`/journal/`, {
      method: "POST",
      body: {
        entries: foundEntries,
        type: type,
      },
    });
  };

  return (
    <div className="thought-page-container">
      <div className="thought-title-container">
        <h1 className="thought-page-title">Entry</h1>
        {/* <Input
          style={{ maxWidth: "calc(100vw - 20px)", width: "800px" }}
          id="thought-search-bar"
          value={search}
          placeholder={"search"}
          onChange={(val: string) => setSearch(val)}
        /> */}
        <div className="flex" style={{ gap: "10px" }}>
          <div className="flex">
            <input
              onChange={(e) => setExperimental(e.target.checked)}
              checked={experimental}
              type="checkbox"
              id="experimental-checkbox"
            />
            <label htmlFor="experimental-checkbox">
              Process Entries Up To Newline
            </label>
          </div>
          <Select
            value={type}
            onChange={(v: string) =>
              v === "spiritual" || v === "thought" || v === "journal"
                ? setType(v)
                : null
            }
            options={[
              { value: "journal", text: "Journal" },
              { value: "spiritual", text: "Spiritual" },
              { value: "thought", text: "Thought" },
            ]}
          ></Select>
        </div>
        <textarea
          onChange={(e) => setText(e.target.value)}
          value={text}
        ></textarea>
        <Button onClick={() => onProcess()}>Process</Button>
      </div>

      {/* <div
        className="thought-container"
        // key={`entry-from-${entry.entry_date}-${entry.entry_id}-${i}`}
      ></div> */}
      {foundEntries.map((entry, i) => {
        return (
          <div
            className="thought-container"
            key={`journal-entry-${entry.date.toString()}-${i}`}
          >
            <div className="thought-date-container">
              <span className="thought-date">
                {new Date(entry.date)
                  .toDateString()
                  .split(" ")
                  .splice(1, 3)
                  .join(" ")}
              </span>
            </div>
            <div className="thought-text">{entry.entry}</div>
          </div>
        );
      })}
      {foundEntries.length > 0 && (
        <div className="padding-bottom-20">
          <Button onClick={() => onSubmitEntries()}>Submit</Button>
        </div>
      )}
    </div>
  );
};

export default JournalEntryPage;
