import axios, { AxiosRequestConfig } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";

const baseURL = process.env.REACT_APP_BASE_URL;

interface UseAuthProps {
  updateToken: (newToken: string | undefined) => void;
  updatePermissions: (newPermissions: UserPermissions) => void;
  createTokenRefreshTimeout: (date: Date) => void;
  permissions: UserPermissions;
  token?: string;
  authLoaded: boolean;
}

export interface UserPermissions {
  post_creating: boolean;
  post_deletion: boolean;
  post_editing: boolean;
  j_rights: boolean;
  st_rights: boolean;
  t_rights: boolean;
  aic: boolean;
  aig: boolean;
}

export const defaultUserPermissions: UserPermissions = {
  post_creating: false,
  post_deletion: false,
  post_editing: false,
  j_rights: false,
  st_rights: false,
  t_rights: false,
  aic: false,
  aig: false,
};

const useAuth: () => UseAuthProps = () => {
  const [token, setToken] = useState<string>();
  const [permissions, setPermissions] = useState<UserPermissions>(
    defaultUserPermissions
  );
  const [authLoaded, setAuthLoaded] = useState(false);
  const tokenTimeout = useRef<NodeJS.Timeout>();

  const updateToken = useCallback(
    (newToken: string | undefined) => {
      setToken(newToken);
      window.localStorage.setItem("token", newToken ?? "");
    },
    [setToken]
  );

  const updatePermissions = useCallback(
    (newPermissions: UserPermissions) => {
      window.localStorage.setItem(
        "permissions",
        JSON.stringify(newPermissions)
      );
      setPermissions(newPermissions);
    },
    [setPermissions]
  );

  const createTokenRefreshTimeout = (date: Date) => {
    window.localStorage.setItem("token_expiration", date.toString());
    //if there is a timeout running currently clear it out.
    if (tokenTimeout && tokenTimeout.current) {
      clearTimeout(tokenTimeout.current);
    }
    //if we want to update the timeout we will want to check if the timeout time is even feasible
    const currentTime = new Date();
    const time = date.getTime() - currentTime.getTime();
    //if the token still has time on it create a timer for it
    if (time > 0) {
      tokenTimeout.current = setTimeout(() => {
        updateToken(undefined);
        updatePermissions(defaultUserPermissions);
      }, time);
      return true;
    } else {
      //if the token is already expired then just update the token credentials to be false
      console.error("Token is already expired");
      updateToken(undefined);
      updatePermissions(defaultUserPermissions);
    }
    return false;
  };

  const updateTokenDetails = async (passedToken: string) => {
    try {
      const options: AxiosRequestConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${passedToken}`,
        },
        url: `${baseURL}${"/auth/token"}`,
        method: "GET",
      };
      const response = await axios(options);
      if (response?.data?.expires && response?.data?.permissions) {
        if (createTokenRefreshTimeout(new Date(response.data.expires))) {
          updatePermissions(response.data.permissions);
          updateToken(passedToken);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getTokenFromOTA = async (tokenOTA: string) => {
    try {
      const options: AxiosRequestConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: `${baseURL}${"/auth/ota"}`,
        method: "POST",
        data: { ota: tokenOTA },
      };
      const response = await axios(options);
      const removeQueryParam = (paramName: string) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.delete(paramName);
        const newUrl = `${
          window.location.pathname
        }?${urlSearchParams.toString()}`;
        window.history.replaceState({}, document.title, newUrl);
      };
      removeQueryParam("tokenOTA");
      if (response?.data?.token) {
        updateToken(response?.data?.token);
        updatePermissions(response?.data?.permissions);
        createTokenRefreshTimeout(new Date(response?.data?.expires));
        setAuthLoaded(true);
      } else {
        loadTokenFromStorage();
      }
    } catch (err) {
      console.error(err);
      loadTokenFromStorage();
      setAuthLoaded(true);
    }
  };

  const loadTokenFromStorage = () => {
    const storedToken = window.localStorage.getItem("token");
    const tokenExpiration = window.localStorage.getItem("token_expiration");
    const expireDate = tokenExpiration ? new Date(tokenExpiration) : undefined;
    const currentTime = new Date();
    //check if our token is expired, if so don't update the token in the context.
    if (expireDate) {
      const dif = expireDate.getTime() - currentTime.getTime();
      if (dif > 0) {
        if (storedToken) {
          updateToken(storedToken);
          createTokenRefreshTimeout(expireDate);
        }
        //check if we have a permissions object in the local storage. if so see if we can parse it and add it to the authentication
        const permissions = window.localStorage.getItem("permissions");
        if (permissions) {
          const parsedPermissions = JSON.parse(permissions);
          if (parsedPermissions) {
            updatePermissions(parsedPermissions as UserPermissions);
          }
        }
      }
      //last thing we need to do is make sure we create a timeout for when the token expires.
    }
    setAuthLoaded(true);
  };

  useEffect(() => {
    //update the context with the stored token.
    if (!token) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      //check if a token was passed in the url
      if (params?.tokenOTA) {
        //we were passed a token in the url. Now we need to get details about the token
        getTokenFromOTA(params?.tokenOTA);
      } else {
        loadTokenFromStorage();
      }
    } else {
      setAuthLoaded(true);
    }
    //when the component unmounts clear out the token timeout
    return () => {
      if (tokenTimeout && tokenTimeout.current) {
        clearTimeout(tokenTimeout.current);
      }
    };
  }, []);

  return {
    authLoaded,
    token,
    updateToken,
    permissions,
    updatePermissions,
    createTokenRefreshTimeout,
  };
};

export default useAuth;
