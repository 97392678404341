import { useContext, useEffect, useState } from "react";
import MC from "../../CMS/MComponent";
import { MComponent } from "../../CMS/types";
import { DataContext } from "../../Context/DataContext";
import { useParams } from "react-router-dom";
import ProjectFooter from "../../Components/Project/ProjectFooter";

const ProjectPage: React.FC = () => {
  const { project } = useParams();
  const [projectContent, setProjectContent] = useState<
    MComponent | undefined
  >();
  const [css, setCss] = useState<string | undefined>();
  const { fetcher } = useContext(DataContext);

  const getPostContent = async () => {
    const response = await fetcher(`/posts/content/${project}`);
    if (response?.data?.content) {
      setProjectContent(response.data.content);
      setCss(response?.data?.data?.css ?? "");
    }
  };

  useEffect(() => {
    getPostContent();
  }, []);
  return (
    <>
      {projectContent && (
        <>
          <style>{css}</style>
          <MC components={projectContent} />
          {/* <ProjectFooter /> */}
        </>
      )}
    </>
  );
};

export default ProjectPage;
