import { useContext, useMemo } from "react";
import { DataContext } from "../../Context/DataContext";
import Error404 from "../../Pages/404/Error404";

interface ProtectedRouteProps {
  children: React.ReactNode | React.ReactNode[];
  permissionTitles?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  permissionTitles,
}) => {
  const { token, permissions } = useContext(DataContext);
  //check if the user actually has the permissions required to view the page
  const isAuthenticated = useMemo(() => {
    const hasPermission = permissionTitles?.every(
      (title) => permissions[title as keyof typeof permissions]
    );
    return hasPermission;
  }, [token, permissions]);
  if (!isAuthenticated) return <Error404 />;
  return <>{token && children}</>;
};

export default ProtectedRoute;
