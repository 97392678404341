import { useContext, useMemo } from "react";
import { DataContext } from "../Context/DataContext";
import { capFirstLetter } from "../Utils/text";

const useProjectsList = () => {
  const { projects } = useContext(DataContext);
  const items = useMemo(() => {
    return projects.map((val) => {
      return {
        title: capFirstLetter(val.small_title),
        link: val.small_title.replaceAll(" ", "-"),
        subtitle: val.year,
        image: val.preview_image,
      };
    });
  }, [projects]);
  return items;
};

export default useProjectsList;
