import { useContext, useMemo, useState } from "react";
import CollapsableTitle from "../../Collapsable/CollapsableTitle";
import { EditorContext } from "../../../Context/EditorContext";

interface CSSEditorProps {}

const format = (val: string) => {
  let string = val;
  const tabText = " ";
  let i = 0;
  let tabs = "";
  while (i < string.length) {
    if (string[i] === "{" || string[i] === "[") {
      tabs += tabText;
      const addTo = `\n${tabs}`;
      string = string.slice(0, i + 1) + addTo + string.slice(i + 1);
      i = i + addTo.length;
    } else if (string[i] === "}" || string[i] === "]") {
      tabs = tabs.slice(0, tabs.length - tabText.length);
      const addTo = `\n${tabs}`;
      string = string.slice(0, i) + addTo + string.slice(i);
      i = i + addTo.length;
    } else if (string[i] === ",") {
      const addTo = `\n${tabs}`;
      string = string.slice(0, i + 1) + addTo + string.slice(i + 1);
      i = i + addTo.length;
    }
    i++;
  }
  return string;
};

const CSSEditor: React.FC<CSSEditorProps> = () => {
  const { data, updateCSS } = useContext(EditorContext);
  const [currentText, setCurrentText] = useState<string>(data?.data?.css ?? "");

  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentText(e.target.value);
    updateCSS(e.target.value);
    // try {
    //   const prepped = e.target.value.replaceAll("\n", "");
    //   const parse = JSON.parse(prepped);
    //   if (parse && typeof parse === "object") {
    //     setInvalid(false);
    //     console.log("updated");
    //     updateContent(parse);
    //   } else {
    //     setInvalid(true);
    //   }
    // } catch (err) {
    //   console.log("parse");
    //   setInvalid(true);
    // }
  };

  return (
    <CollapsableTitle title="CSS Classes">
      <div className="editor-collapsable">
        <textarea
          value={currentText}
          name="test"
          cols={30}
          rows={40}
          onChange={onTextAreaChange}
        ></textarea>
        <style>{currentText}</style>
      </div>
    </CollapsableTitle>
  );
};

export default CSSEditor;
