import { Link } from "react-router-dom";
import useNavLoc from "../../Hooks/Nav/useNavLoc";
import HamburgerNav from "./HamburgerNav";
import usePermissionsNav from "../../Hooks/Nav/usePermissionsNav";

const DesktopNav: React.FC = () => {
  const { links } = usePermissionsNav();
  const { isBaseRoute, isProjectRoute, isUnlockedThoughts, isOther } =
    useNavLoc();
  return (
    <div className="nav-container">
      {isBaseRoute && (
        <>
          <div className="nav-section">
            {links.length > 0 && <HamburgerNav links={links} />}
            <Link className="link-no-dec nav-header" to={"/"}>
              <div className="nav-header">TJ Luebke</div>
            </Link>
          </div>
          <div className="nav-section" style={{ padding: "0px 30px" }}>
            <span
              className="nav-text nav-link"
              onClick={() => {
                window.location.hash = "#project-section";
              }}
            >
              Projects
            </span>
            <span
              className="nav-text nav-link"
              onClick={() => {
                window.location.hash = "#about-section";
              }}
            >
              About
            </span>
            <span
              onClick={() => {
                window.location.hash = "#experience-section";
              }}
              className="nav-text nav-link"
            >
              Experience
            </span>
            <span
              onClick={() => {
                window.location.hash = "#contact-section";
              }}
              className="nav-text nav-link"
            >
              Contact
            </span>
          </div>
        </>
      )}
      {isProjectRoute && (
        <>
          <div className="nav-grid">
            <div className="nav-section">
              {links.length > 0 && <HamburgerNav links={links} />}
              <Link className="link-no-dec nav-header" to={"/"}>
                TJ Luebke
              </Link>
            </div>
            <div className="nav-header nav-header-2">Projects</div>
            <Link to={"/"} className="nav-text nav-text-2">
              Return Home
            </Link>
          </div>
        </>
      )}
      {isUnlockedThoughts && (
        <>
          <div className="nav-grid">
            <div className="nav-section">
              {links.length > 0 && <HamburgerNav links={links} />}
              <Link className="link-no-dec nav-header" to={"/"}>
                TJ Luebke
              </Link>
            </div>
            <div className="nav-header nav-header-2">Thoughts</div>
            <Link to={"/"} className="nav-text nav-text-2">
              Return Home
            </Link>
          </div>
        </>
      )}
      {isOther && (
        <>
          <div className="nav-grid">
            <div className="nav-section">
              {links.length > 0 && <HamburgerNav links={links} />}
              <Link className="link-no-dec nav-header" to={"/"}>
                TJ Luebke
              </Link>
            </div>
            <div></div>
            <Link to={"/"} className="nav-text nav-text-2">
              Return Home
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default DesktopNav;
