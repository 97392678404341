import { Outlet } from "react-router-dom";
import useWinSize from "../../Hooks/useWinSize";
import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";

const Navbar: React.FC = () => {
  const { isSmall } = useWinSize();
  return (
    <>
      {!isSmall && (
        <>
          <DesktopNav />
        </>
      )}
      {isSmall && <MobileNav />}
      <div style={{ height: "58px" }}></div>
      <Outlet />
    </>
  );
};

export default Navbar;
