import { MComponent } from "../CMS/types";
import { ProjectData } from "../Components/Editor/types";

export const initialContentData: MComponent = {
  tag: "div",
  id: "test",
  c: "loading",
};

export const initialDataContent: ProjectData = {
  id: 0,
  title: "",
  small_title: "",
  content_id: 0,
  preview_image: "",
  year: "0000",
  status: "draft",
  date_created: "0",
  content: initialContentData,
  data: {},
};
