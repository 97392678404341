import { useContext, useEffect, useState } from "react";
import Input from "../../../Input/Input";
import Modal from "../../../Modal/Modal";
import Button from "../../../Button/Button";
import FileUpload from "../../../Input/FileUpload/FileUpload";
import useDebounce from "../../../../Hooks/useDebounce";
import { EditorContext } from "../../../../Context/EditorContext";

interface UploadEditorModals {
  title: string;
  onSearchChange: (val: string) => void;
  onClose?: () => void;
}

const UploadEditorUploading: React.FC<UploadEditorModals> = ({
  title,
  onSearchChange,
  onClose,
}) => {
  const { data } = useContext(EditorContext);
  const [modalOpened, setModalOpened] = useState(false);
  const [search, setSearch] = useState("");
  const { debounce } = useDebounce(1000);

  useEffect(() => {
    debounce(() => onSearchChange(search));
  }, [search]);

  const handleClose = () => {
    setModalOpened(false);
    onClose?.();
  };

  return (
    <>
      <Modal
        onClose={() => handleClose()}
        title={`Upload ${title}s`}
        opened={modalOpened}
      >
        <div className="editor-upload-modal-container">
          <FileUpload
            subfile={(data.small_title + "")?.replace(" ", "-")}
            id={`${title}-uploader`}
            uploadTo={`/upload/${title.toLowerCase()}`}
          />
        </div>
      </Modal>
      <div className="editor-upload-title-container">
        <Input
          style={{ width: "80%" }}
          id={`${title.toLowerCase()}-search-input`}
          value={search}
          onChange={(val: string) => setSearch(val)}
        />
        <Button
          onClick={() => setModalOpened(true)}
          style={{ width: "10%", padding: "5px 0px" }}
        >
          +
        </Button>
      </div>
    </>
  );
};

export default UploadEditorUploading;
