import { ExperienceBlock } from "./ExperienceBlock";

const ExperienceText: React.FC = () => {
  return (
    <div className="experience-text-container">
      <ExperienceBlock
        title="Nerds United"
        startDate="2022"
        endDate="2023"
        position="Jr. Software Developer"
        description="Helping develop software for a brand called SwitchRewardCard. A Company that utilizes crypto and real world credit cards enabling users to buy with crypto through their credit card. Earning rewards on the blockchain through transactions."
        projectLink=""
      />
      <ExperienceBlock
        title="DevMountain"
        startDate="2021"
        endDate="2022"
        position="Student Developer"
        description="Studying at DevMountain, to get more connections and build my foundation in Software Engineering"
        projectLink=""
      />
    </div>
  );
};

export default ExperienceText;
