import OverflowContainer from "../Container/OverflowContainer";
import CarousalHTML from "./CarousalHTML";

export interface CarousalProps {
  items: string[];
}

const Carousal: React.FC<CarousalProps> = ({ items }) => {
  return (
    <OverflowContainer>
      <CarousalHTML items={items}></CarousalHTML>
    </OverflowContainer>
  );
};

export default Carousal;
