import { useState } from "react";
import { ReactComponent as CopyIcon } from "../../SVGs/copy.svg";
import { ReactComponent as CheckIcon } from "../../SVGs/check.svg";

interface CopyProps {
  value: string;
}

const Copy: React.FC<CopyProps> = ({ value }) => {
  const [copied, setCopied] = useState(false);

  const handleCopied = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.bubbles = false;
    e.preventDefault();
    navigator.clipboard.writeText(value);
    if (copied === false) {
      setCopied(true);
      setTimeout(() => {
        setCopied((copyVal) => false);
      }, 1000);
    }
  };

  return (
    <div className="copy-container" onClick={handleCopied}>
      {" "}
      {!copied && <CopyIcon />}
      {copied && <CheckIcon />}
    </div>
  );
};

export default Copy;
