import { PxPercStringToNumber } from "../../Particles/canvasReader/util";

const Test = () => {
  const test = "min(100,10%)";
  PxPercStringToNumber(test, false);

  return <div>{test}</div>;
};

export default Test;
