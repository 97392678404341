import { useContext, useEffect, useRef, useState } from "react";
import { DataContext } from "../../Context/DataContext";

interface CheckAppStatusProps {
  children: React.ReactNode | React.ReactNode[];
}

const CheckAppStatus: React.FC<CheckAppStatusProps> = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const [loadedStyles, setLoadedStyles] = useState(false);
  const cssStatus = useRef(0);
  const fontStatus = useRef(0);
  const intervalRef = useRef<string | number | NodeJS.Timeout | undefined>();
  const { loaded: dataLoaded } = useContext(DataContext);

  const unlockApp = () => {
    setLoaded(true);
    const loadingPortal = document.getElementById("loading-portal");
    if (loadingPortal) {
      const child = loadingPortal.children?.[0];
      if (child) {
        child.classList.add("fade-out");
        setTimeout(() => {
          loadingPortal.innerHTML = "";
        }, 500);
      }
    }
  };

  const checkCSSStatus = () => {
    const styles = window.getComputedStyle(document.body);
    // checks to see if the css variable has been set, if it has then the css has loaded
    const cssLoaded = styles.getPropertyValue("--css-init");
    if (cssLoaded) {
      cssStatus.current = 1;
    }
  };

  const checkFonts = () => {
    //checks if the font families have been loaded
    const loadedPoppinsReg = document.fonts.check("500 70px Poppins");
    const loadedPoppinsSemiB = document.fonts.check("600 70px Poppins");
    const loadedPoppinsB = document.fonts.check("700 70px Poppins");
    const loadedSintonyReg = document.fonts.check("500 70px Sintony");
    const loadedSintonySemiB = document.fonts.check("600 70px Sintony");
    if (
      loadedPoppinsReg &&
      loadedPoppinsSemiB &&
      loadedPoppinsB &&
      loadedSintonyReg &&
      loadedSintonySemiB
    ) {
      fontStatus.current = 1;
    }
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (cssStatus.current === 0) {
        checkCSSStatus();
      }
      if (fontStatus.current === 0) {
        checkFonts();
      }
      if (cssStatus.current === 1 && fontStatus.current === 1) {
        clearInterval(intervalRef.current);
        setLoadedStyles(true);
      }
    }, 200);
    checkFonts();
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (loadedStyles && dataLoaded) {
      unlockApp();
    }
  }, [loadedStyles, dataLoaded]);

  if (loaded) {
    return <>{children}</>;
  } else {
    return (
      <>
        {/* These force the font faces to load */}
        <div style={{ opacity: 0, fontFamily: "Poppins", fontWeight: 500 }}>
          a
        </div>
        <div style={{ opacity: 0, fontFamily: "Poppins", fontWeight: 600 }}>
          a
        </div>
        <div style={{ opacity: 0, fontFamily: "Poppins", fontWeight: 700 }}>
          a
        </div>
        <div style={{ opacity: 0, fontFamily: "Sintony", fontWeight: 500 }}>
          a
        </div>
        <div style={{ opacity: 0, fontFamily: "Sintony", fontWeight: 600 }}>
          a
        </div>
      </>
    );
  }
};

export default CheckAppStatus;
