import { MComponent } from "../CMS/types";

// export const carousalTemplate: MComponent = {
//   tag: "div",
//   id: "test",
//   c: [{ tag: "div", id: "test-2", c: "test" }],
// };
export const carousalTemplate: MComponent = {
  id: "main-container",
  tag: "div",
  c: [
    {
      id: "project-container-1",
      tag: "ProjectContainer",
      title: "Switch Reward Card",
      year: "2022",
      colSizes: ["100%", "49%", "49%"],
      c: [
        {
          id: "top-container-1",
          tag: "div",
          c: [
            {
              id: "top-container-title-1",
              tag: "h3",
              c: "Worked as a Jr Developer",
            },
            {
              id: "top-container-description",
              tag: "p",
              c: `Switch was a brand I worked for while at Nerds United. My role was
  mainly a frontend developer working in a react like library called
  Next.TS. I developed components and helped build a lot of the
  transaction flow and modals. I also created basic components that
  made our developing jobs easier, like tooltips that auto positioned
  themselves. but I transitioned to working full stack and developing
  parts of the backend in .NET.`,
            },
          ],
        },
        {
          id: "img-container-first",
          tag: "div",
          c: [
            {
              id: "first-img",
              tag: "img",
              class: "project-img-float-left",
              src: "/projects/switch/pic1.png",
            },
          ],
        },
        {
          id: "mid-container-1",
          tag: "div",
          c: [
            { tag: "h3", id: "mid-container-1-title", c: "About Switch" },
            {
              id: "mid-container-1-description",
              tag: "p",
              c: `The concept behind Switch was utilizing crypto and real world credit
    cards to enable users to purchase items with crypto through their
    credit card. All while Earning rewards on the blockchain through
    transactions.`,
            },

            {
              id: "mid-container-1-img-1",
              tag: "img",
              class: "project-img-float-right",
              src: "/projects/switch/pic2.png",
            },
            {
              id: "mid-container-1-img-2",
              tag: "div",
              class: "is-desktop",
              style: { height: "20vw" },
              c: "",
            },
          ],
        },
      ],
    },
  ],
};
