import { useMemo } from "react";

interface SuggestionsProps {
  id: string;
  suggestions: string[];
  curVal?: string;
  onSelect: (val: string) => void;
}

const Suggestions: React.FC<SuggestionsProps> = ({
  curVal,
  suggestions,
  id,
  onSelect,
}) => {
  const curSuggestions = useMemo(() => {
    return suggestions.filter((val) => val.includes(curVal ?? ""));
  }, [curVal, suggestions]);
  return (
    <div className="relative suggestions-container">
      {curVal && curSuggestions.length > 0 && (
        <div className="suggestions-box">
          {curSuggestions.map((val, i) => (
            <div
              onClick={() => onSelect(val)}
              className="suggestion-item"
              key={`${id}-suggestions-${i}-${val}`}
            >
              {val}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Suggestions;
