import { CSSProperties, useState } from "react";
import { ReactComponent as DeleteIcon } from "../../SVGs/delete.svg";
import Suggestions from "../Suggestions/Suggestions";
import useDragDropList from "../../Hooks/useDragDropList";

interface ChipsProps {
  id: string;
  chips?: string[];
  suggestions?: string[];
  onChange: (vals: string[]) => void;
  label?: string;
  style?: CSSProperties;
  className?: string;
  draggable?: boolean;
}

const Chips: React.FC<ChipsProps> = ({
  id,
  label,
  onChange,
  style,
  chips,
  className,
  suggestions,
  draggable = false,
}) => {
  const [input, setInput] = useState("");
  const { onDrag, onDragOver, onDrop, onDragLeave } = useDragDropList({
    list: chips ?? [],
    onChange: (vals: any[]) => onChange(vals),
    useDragNDrop: draggable,
  });

  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onChange([...(chips ?? []), input]);
      setInput("");
    }
  };

  const handleChipDeletion = (indx: number) => {
    const test = [...(chips ?? [])];
    test.splice(indx, 1);
    onChange(test);
  };

  const handleSuggestion = (val: string) => {
    onChange([...(chips ?? []), val]);
    setInput("");
  };

  return (
    <div style={style} className={"chips-container" + className}>
      {label && (
        <label className="input-label" htmlFor={id}>
          {label}
        </label>
      )}
      <label htmlFor={id} className="portfolio-input-border chips">
        {(chips ?? []).map((val, i) => (
          <div
            onDrag={() => onDrag(i)}
            onDrop={() => onDrop(i)}
            onDragLeave={onDragLeave}
            draggable={draggable}
            onDragOver={(e) => onDragOver(e, i)}
            className="chip-input-chip"
            key={`${id}-chips-${i}-${val}`}
          >
            {val}
            <DeleteIcon
              onClick={() => handleChipDeletion(i)}
              className="chip-input-delete"
            />
          </div>
        ))}
        <div>{input}</div>
        <input
          onKeyDown={handleKey}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          id={id}
          className="chips-input"
        ></input>
        {suggestions && (
          <Suggestions
            onSelect={handleSuggestion}
            curVal={input}
            suggestions={suggestions}
            id={`chip-suggestions-${id}`}
          />
        )}
      </label>
    </div>
  );
};

export default Chips;
