import { useCallback, useEffect, useRef, useState } from "react";
import { DEFAULT_WRAPPER_OPTIONS } from "./utils/options";
import {
  DPIRef,
  DefaultedWrapperOptions,
  ParticleController,
  WrapperOptions,
} from "./types";
import { MouseCursor, initialMouseCursorObject } from "./types/mouse";
import useMouseCursor from "./hooks/useMouseCursor";
import {
  excludeOldMouseEntries,
  removeInitalAndLiftingClicks,
} from "./utils/mouse";
import useFPS from "./hooks/useFPS";
import { initializeGLRenderingContext } from "./webgl/initialization/webGLContext";
import { GLDeps } from "./types/webgl";
import { webGLLoop } from "./webgl/loop";
import { cleanUpGL } from "./webgl/cleanup";
import { inititalizeGLDependencies } from "./webgl/initialization/dependencies";
import useCanvasReader from "./CanvasReader";
import useGroupController from "./hooks/useGroupController";
import {
  GroupAction,
  GroupIndividualAction,
  ParticleGroups,
} from "./types/groups";

interface ParticleWrapperProps {
  controllerRef?: React.MutableRefObject<ParticleController>;
  onInitalized?: () => void;
  options?: WrapperOptions;
  enabled: boolean;
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  // initParticlePointsFunc: (width: number, height: number) => Particle;
}

const Particles: React.FC<ParticleWrapperProps> = ({
  options,
  controllerRef,
  onInitalized,
  enabled,
  setEnabled,
}) => {
  useEffect(() => {
    return () => {
      if (glRef.current && glDepsRef.current)
        cleanUpGL(glRef.current, glDepsRef.current);
    };
  }, []);
  const canvasRef = useRef<HTMLCanvasElement>();
  const groupsRef = useRef<ParticleGroups>([]);
  const glRef = useRef<WebGL2RenderingContext>();
  const glDepsRef = useRef<GLDeps>();
  const animationRef = useRef(-1);
  const mouseRef = useRef<MouseCursor>(initialMouseCursorObject);
  const dpiRef = useRef<DPIRef>({
    dpi: window.innerWidth < 1000 ? window.devicePixelRatio || 1 : 1,
    lastUpdate: new Date().getTime(),
  });
  const [dpi, setDpi] = useState(
    window.innerWidth < 1000 ? window.devicePixelRatio || 1 : 1
  );
  const [hasInitialized, setHasInitialized] = useState(false);
  /** TODO */
  //   const particleQueue = useRef<ParticleQueue[]>([]);

  const { updateFPS, renderFPSOnCanvas, fpsRef } = useFPS();
  const settings: DefaultedWrapperOptions = {
    ...DEFAULT_WRAPPER_OPTIONS,
    ...options,
  };
  const {
    addFromPoints,
    disableGroups,
    enableGroups,
    deleteAllGroups,
    setGroupLifetime,
  } = useGroupController({
    glRef,
    depsRef: glDepsRef,
    groupsRef,
    settings,
    canvasRef,
    dpiRef,
  });
  const { handleCanvasRef, hasCTX, addInputs } = useCanvasReader({
    width: canvasRef.current?.width ?? window.innerWidth,
    height: canvasRef.current?.height ?? window.innerHeight,
    settings,
    addFromPoints,
    dpiRef,
  });

  useMouseCursor(mouseRef, canvasRef);

  //particle updating methods
  const loop = useCallback(() => {
    excludeOldMouseEntries(mouseRef);
    const curTime = new Date().getTime();
    const timeSinceStart = curTime - fpsRef.current.timeStart;
    if (timeSinceStart > 3000 && timeSinceStart < 12000 && controllerRef) {
      if (
        fpsRef.current.fps < 35 &&
        dpiRef.current.dpi > 1 &&
        curTime - dpiRef.current.lastUpdate > 1000
      ) {
        dpiRef.current.dpi = dpiRef.current.dpi - 0.25;
        dpiRef.current.lastUpdate = curTime;
        setDpi(dpiRef.current.dpi);
      }
      if (fpsRef.current.fps < 30) {
        setEnabled(false);
        cancelAnimationFrame(animationRef.current);
        if (glRef.current && glDepsRef.current) {
          cleanUpGL(glRef.current, glDepsRef.current);
        }
        return;
      }
    }
    //render and update here
    if (glRef.current && glDepsRef.current) {
      webGLLoop(
        glRef.current,
        glDepsRef.current,
        settings,
        mouseRef.current,
        canvasRef.current?.offsetWidth ?? window.innerWidth,
        canvasRef.current?.offsetHeight ?? window.innerHeight,
        groupsRef.current,
        dpiRef.current.dpi
      );
    }

    mouseRef.current.scrollDY = 0;
    updateFPS();
    removeInitalAndLiftingClicks(mouseRef);
    if (enabled) {
      animationRef.current = requestAnimationFrame(loop);
    }
  }, [glRef]);

  //if elements in the animation are updated just reset the animation frame so it loads up the new variables
  useEffect(() => {
    if (enabled === true) {
      cancelAnimationFrame(animationRef.current);
      loop();
    }
  }, [glRef]);

  //------------------------ handle visibility change ------------------------

  //add in the event listeners for when the tab changes
  useEffect(() => {
    const handleWindowVisibility = (e: any) => {
      if (enabled) {
        if (document.visibilityState === "visible") {
          if (glDepsRef.current) {
            //update the last render time, that way it isn't like 20 seconds since last render and it doesn't move the particles all around the screen trying to compensate
            glDepsRef.current.lastRenderTime = performance.now();
            loop();
          }
        } else {
          cancelAnimationFrame(animationRef.current);
        }
      }
    };
    if (enabled) {
      window.addEventListener("visibilitychange", handleWindowVisibility);
    }
    return () => {
      window.removeEventListener("visibilitychange", handleWindowVisibility);
    };
  }, [glDepsRef]);
  //make sure to delete the visibility event listener when the component is destroyed

  //------------------------ controller creation ------------------------
  useEffect(() => {
    if (glDepsRef.current && glRef.current && controllerRef && hasCTX) {
      controllerRef.current = {
        deleteAllGroups,
        setGroupLifetime,
        enableGroups,
        disableGroups,
        addInputGroup: addInputs,
        ready: true,
        groupAction: (
          action: GroupAction | GroupIndividualAction,
          group: number
        ) => {
          if (groupsRef.current[group]) {
            groupsRef.current[group].action = action;
          }
        },
        enabled: true,
      };
      onInitalized?.();
    }
  }, [glDepsRef, glRef, hasCTX, addInputs]);

  //------------------------ initialization ------------------------
  const handleRefInitialization = (ref: HTMLCanvasElement | null) => {
    if (ref && !hasInitialized) {
      const gl = initializeGLRenderingContext(ref);
      if (gl) {
        glRef.current = gl;
        canvasRef.current = ref;
        //set up the dpi;
        canvasRef.current.width = window.innerWidth * dpiRef.current.dpi;
        canvasRef.current.height = window.innerHeight * dpiRef.current.dpi;
        glRef.current.viewport(
          0,
          0,
          window.innerWidth * dpiRef.current.dpi,
          window.innerHeight * dpiRef.current.dpi
        );
        const width = window.innerWidth * dpiRef.current.dpi;
        const height = window.innerHeight * dpiRef.current.dpi;
        const deps = inititalizeGLDependencies(gl, settings, width, height);
        // const deps = initTestDeps(gl, width, height, settings);
        if (deps) {
          glDepsRef.current = deps;
          setHasInitialized(true);
        }
      }
    }
  };
  // useEffect(() => {
  //   if (glRef.current && glDepsRef.current)
  //     cleanUpGL(glRef.current, glDepsRef.current);
  // }, [glRef, glDepsRef]);

  // This function adjusts the size of the canvas and the WebGL viewport
  // to match the size of the window.

  useEffect(() => {
    if (glRef.current && canvasRef.current) {
      canvasRef.current.width = window.innerWidth * dpi;
      canvasRef.current.height = window.innerHeight * dpi;
      glRef.current.viewport(
        0,
        0,
        window.innerWidth * dpi,
        window.innerHeight * dpi
      );
      if (dpi !== dpiRef.current.dpi) {
        dpiRef.current.dpi = dpi;
        dpiRef.current.lastUpdate = new Date().getTime();
      }
    }
  }, [dpi]);

  useEffect(() => {
    const adjustSize = () => {
      if (glRef.current && canvasRef.current) {
        canvasRef.current.width = window.innerWidth * dpiRef.current.dpi;
        canvasRef.current.height = window.innerHeight * dpiRef.current.dpi;
        glRef.current.viewport(
          0,
          0,
          window.innerWidth * dpiRef.current.dpi,
          window.innerHeight * dpiRef.current.dpi
        );
      }
    };
    window.addEventListener("resize", adjustSize);
    return () => window.removeEventListener("resize", adjustSize);
  }, [glRef, canvasRef]);

  //wait till the other canvas has been intialized before doing any heavy duty gl initialization
  const handleInitalization = hasCTX;

  return (
    <>
      {handleInitalization && (
        <canvas
          className="canvas-particles"
          ref={handleRefInitialization}
          width={window.innerWidth}
          height={window.innerHeight}
        />
      )}
      <div className="canvas-render-container debug">
        <canvas
          className="canvas-render"
          style={{ fontSmooth: "never", WebkitFontSmoothing: "none" }}
          ref={handleCanvasRef}
          width={canvasRef.current?.offsetWidth ?? window.innerWidth}
          height={canvasRef.current?.offsetHeight ?? window.innerHeight}
        ></canvas>
      </div>
    </>
  );
};

export default Particles;
