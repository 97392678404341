import { CSSProperties } from "react";

interface InputProps {
  id: string;
  value: string;
  onChange: (val: string) => void;
  style?: CSSProperties;
  className?: string;
  label?: string;
  children?: React.ReactNode | React.ReactNode[];
  labelStyle?: CSSProperties;
  placeholder?: string;
}

const Input: React.FC<InputProps> = ({
  className,
  labelStyle,
  style,
  label,
  id,
  value,
  onChange,
  children,
  placeholder,
}) => {
  return (
    <div style={style} className={`input-container ${className ?? ""}`}>
      {label && (
        <label className="input-label" htmlFor={id} style={labelStyle}>
          {label}
        </label>
      )}
      <label htmlFor={id} className="portfolio-input-border">
        <input
          placeholder={placeholder}
          value={value ?? ""}
          className="portfolio-input"
          onChange={(e) => onChange(e.target.value)}
          id={id}
        ></input>
        {children}
      </label>
    </div>
  );
};

export default Input;
