const Error404: React.FC = () => {
  //TODO make this use the particle engine to display everything in particles that are interactive
  return (
    <div className="error-404-container">
      <h1>404</h1>
      <h2>Not Found</h2>
    </div>
  );
};

export default Error404;
