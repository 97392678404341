import useWinSize from "../../Hooks/useWinSize";

interface SpinnerImageProps {
  img: string;
  resting?: boolean;
}

const SpinnerImage: React.FC<SpinnerImageProps> = ({ img, resting = true }) => {
  const { isSmall, isMobile } = useWinSize();
  return (
    <div
      className={`spinner-image-container ${
        (isMobile || isSmall) && !resting ? "hidden" : ""
      }`}
    >
      <img className="spinner-image" src={img}></img>
      <div className="spinner-image-border"></div>
    </div>
  );
};

export default SpinnerImage;
