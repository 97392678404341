import { RouteObject } from "react-router";
import PageController from "../../Pages/PageController";
import Error404 from "../../Pages/404/Error404";
import Navbar from "../Navbar/Navbar";
import ProjectPage from "../../Pages/Project/ProjectPage";
import AuthPage from "../../Pages/Auth/AuthPage";
import Test from "../../Pages/Test/Test";
import ProtectedRoute from "../Auth/ProtectedRoute";
import AdminProjectsPage from "../../Pages/Admin/AdminProjectsPage";
import AdminEditor from "../../Pages/Admin/AdminEditor";
import { EditorProvider } from "../../Context/EditorContext";
import UnlockedThoughtsPage from "../../Pages/UnlockedThoughts/UnlockedThoughts";
import JournalEntryPage from "../../Pages/JournalEntry/JournalEntryPage";
import TJRedirectPage from "../../Pages/TJRedirect/TJRedirectPage";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Navbar />,
    errorElement: <Error404 />,
    children: [
      {
        errorElement: <Error404 />,
        children: [
          { index: true, element: <PageController /> },
          {
            path: "/tj",
            element: <TJRedirectPage />,
          },
          {
            path: "/projects/:project",
            element: <ProjectPage />,
          },
          { path: "/when-a-forklift-lifts-a-fork", element: <AuthPage /> },
          { path: "/test", element: <Test /> },
          {
            path: "/unlocked_thoughts",
            element: (
              <ProtectedRoute permissionTitles={["t_rights"]}>
                <UnlockedThoughtsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: "/journal_entry",
            element: (
              <ProtectedRoute permissionTitles={["j_rights"]}>
                <JournalEntryPage />
              </ProtectedRoute>
            ),
          },
          {
            path: "/admin",
            children: [
              {
                path: "/admin/projects",
                element: (
                  <ProtectedRoute
                    permissionTitles={[
                      "post_editing",
                      "post_creating",
                      "post_deletion",
                    ]}
                  >
                    <AdminProjectsPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: "/admin/projects/:id",
                element: (
                  <ProtectedRoute
                    permissionTitles={[
                      "post_editing",
                      "post_creating",
                      "post_deletion",
                    ]}
                  >
                    <EditorProvider>
                      <AdminEditor />
                    </EditorProvider>
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
