import React, { CSSProperties, useMemo } from "react";
import useWinSize from "../../Hooks/useWinSize";

type OutlineTitlePositions = "left" | "right" | "center";

interface OutlineContainerProps {
  children: React.ReactNode | React.ReactNode[];
  colSizes: string[];
  title?: string;
  titlePos?: OutlineTitlePositions;
  noPaddingIndices?: number[];
  className?: string;
  style?: CSSProperties;
  noRelativeIndices?: number[];
}

const OutlineContainer: React.FC<OutlineContainerProps> = ({
  children,
  colSizes,
  title,
  titlePos = "center",
  noPaddingIndices,
  className,
  noRelativeIndices,
  style,
}) => {
  const { isSmall, isMobile, isTablet } = useWinSize();
  const titleClass = useMemo(
    () =>
      titlePos === "right"
        ? "outline-title-right"
        : titlePos === "left"
        ? "outline-title-left"
        : "outline-title",
    [titlePos]
  );
  return (
    <div
      id={title ? `${title.toLowerCase()}-section` : `outline-section`}
      className={`outline-container relative ${className}`}
      style={style}
    >
      {React.Children.map(children, (child, i: number) => {
        //caclualate the column size
        const usePadding = !noPaddingIndices?.includes(i);
        const currentPadding = !usePadding
          ? "0px"
          : isMobile
          ? "40px"
          : isSmall
          ? "60px"
          : isTablet
          ? "140px"
          : "188px";
        const size =
          isSmall || isMobile || isTablet
            ? "100%"
            : colSizes?.[i] ?? colSizes?.[0] ?? "100%";

        const width = `calc(${size} - ${currentPadding})`;
        //return the item

        return (
          <div
            className={`outline-section ${
              noRelativeIndices?.includes(i) ? "" : "relative"
            }`}
            style={{ width, ...(usePadding ? {} : { padding: "0px" }) }}
          >
            {child}
          </div>
        );
      })}
      {title && (
        <div
          id={`${title.toLowerCase()}-title`}
          className={`outline-text ${titleClass}`}
        >
          <div className="outline-tab">{title}</div>
        </div>
      )}
    </div>
  );
};

export default OutlineContainer;
