import { CSSProperties } from "react";
import Carousal from "./Carousal";

interface ResourcesCarousalProps {
  carousalData: string[];
  tabPosition?: "left" | "right";
  onClick?: (e: any) => void;
  style?: CSSProperties;
  className?: string;
}

const ResourcesCarousal: React.FC<ResourcesCarousalProps> = ({
  carousalData,
  onClick,
  tabPosition = "left",
}) => {
  return (
    <>
      <div className="space-2"></div>
      <div onClick={onClick} className="relative" style={{ width: "100%" }}>
        <div
          className={`${
            tabPosition === "left"
              ? "outline-title-left"
              : "outline-title-right"
          } outline-title-2`}
        >
          <div className="outline-tab outline-tab-2">Resources</div>
        </div>
        <Carousal items={carousalData} />
      </div>
    </>
  );
};

export default ResourcesCarousal;
