import { useState } from "react";
import SelectSpinner from "../../../Components/SelectSpinner/SelectSpinner";
import useWinSize from "../../../Hooks/useWinSize";
import useProjectsList from "../../../Hooks/useProjectsList";

const test = [
  {
    title: "test1",
    link: "test",
    subtitle: "2012",
    image: "test",
  },
  {
    title: "test2",
    link: "test",
    subtitle: "2012",
    image: "test",
  },
];

const ProjectsSection: React.FC = () => {
  const [selected, setSelected] = useState<number | undefined>(undefined);
  const { isSmall, isMobile, isLarge, isDesktop, isMid, isTablet } =
    useWinSize();
  const width = isSmall
    ? "min(90vw,350px)"
    : isTablet && !isMid
    ? "300px"
    : isMid && !isDesktop
    ? "500px"
    : "700px";
  const height = isSmall ? "min(80vw,300px)" : "500px";
  const projects = useProjectsList();
  return (
    <div>
      <div style={{ height: "500px" }}></div>
      <SelectSpinner
        angleIncrement={
          isMobile ? 35 : isSmall ? 30 : isLarge || isDesktop ? 20 : 35
        }
        style={isSmall ? { width: "100%" } : { width: "50%" }}
        dis={isMobile ? 120 : isSmall ? 160 : isTablet ? 160 : 300}
        offsetX={isSmall ? -10 : isTablet ? -3 : 0}
        fontSize={
          isMobile ? "35px" : isSmall ? "40px" : isTablet ? "40px" : "50px"
        }
        options={projects}
        onSelect={(val: number) => setSelected(val)}
        id={"project-section"}
      />
    </div>
    // <div
    //   id="projects"
    //   style={{ height: "100vh", overflow: "visible", position: "relative" }}
    // >
    //   <div style={{ zIndex: 2 }}>
    //     <SelectSpinner
    //       angleIncrement={
    //         isMobile ? 35 : isSmall ? 30 : isLarge || isDesktop ? 30 : 35
    //       }
    //       style={isSmall ? { width: "100%" } : { width: "50%" }}
    //       dis={isMobile ? 25 : isSmall ? 20 : isTablet ? 10 : 9}
    //       offsetX={isSmall ? -10 : isTablet ? -3 : 0}
    //       fontSize={isMobile ? "35px" : isSmall ? "40px" : "50px"}
    //       options={projects}
    //       onSelect={(val: number) => setSelected(val)}
    //     ></SelectSpinner>
    //   </div>
    //   <div
    //     style={{ position: "absolute", right: "0%", top: "50%", zIndex: -1 }}
    //   >
    //     <img
    //       height={500}
    //       style={{
    //         transform: isMobile ? "translate(10%,-50%)" : "translate(0%,-50%)",
    //         borderRadius: "20px",
    //         zIndex: 0,
    //         objectFit: "cover",
    //         width: width,
    //         height: height,
    //         filter: isSmall ? "opacity(15%)" : "none",
    //       }}
    //       src={projects[selected ?? 0]?.image}
    //     ></img>
    //     {!isSmall && (
    //       <div
    //         style={{
    //           zIndex: -3,
    //           position: "absolute",
    //           right: "calc(0% + 35px)",
    //           top: "calc(-50% - 35px)",
    //           width: "100%",
    //           height: "100%",
    //           border: "1px solid black",
    //           borderRadius: "20px",
    //         }}
    //       ></div>
    //     )}
    //   </div>
    // </div>
  );
};

export default ProjectsSection;
