import axios from "axios";
import { useContext, useState } from "react";
import { DataContext } from "../../Context/DataContext";

const baseURL = process.env.REACT_APP_BASE_URL;

export const AuthPage: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const { updateToken, updatePermissions, createTokenRefreshTimeout } =
    useContext(DataContext);

  const handleAuthenticate = async () => {
    try {
      //hit the login route
      if (!token) {
        const res: any = await axios.post(`${baseURL}/auth/login`, {
          username,
          password,
        });
        if (res?.data?.token) {
          setToken(res?.data?.token);
        }
        //hit the 2fa route, and see if our token and code is good.
      } else {
        const res: any = await axios.post(
          `${baseURL}/auth/2fa`,
          {
            username,
            code: password,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (res?.data?.token) {
          updateToken(res?.data?.token);
          updatePermissions(res?.data?.permissions);
          createTokenRefreshTimeout(new Date(res?.data?.expires));
          setToken(res?.data?.token);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex-center">
      {!token && (
        <>
          <input onChange={(e) => setUsername(e.target.value)}></input>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </>
      )}
      {token && (
        <>
          <input onChange={(e) => setPassword(e.target.value)}></input>
        </>
      )}
      <button onClick={handleAuthenticate}>Authenticate</button>
    </div>
  );
};

export default AuthPage;
