import { ImageLoaderImage } from "../../../../Particles/hooks/useImageLoader";
import { ParticleController } from "../../../../Particles/types";
import {
  ParticleImageInput,
  ParticleInput,
} from "../../../../Particles/types/particleReader";

const imagePos = [
  { x: 200 + Math.random() * 400, y: 40 + Math.random() * 50 },
  { x: 0 + 180 + Math.random() * 200, y: Math.random() * 200 },
  { x: 180 + Math.random() * 200, y: 10 + Math.random() * 55 },
  { x: Math.random() * 200 + 350, y: Math.random() * 350 + 200 },
];

export const renderLinks = (
  p: ParticleController,
  images: ImageLoaderImage[],
  curFrame: number,
  setFormOpened: React.Dispatch<React.SetStateAction<boolean>>,
  isMobile: boolean,
  isTablet: boolean
) => {
  const defaultFontSize = 70;
  const curFontSize = 30 + 40 * (window.innerWidth / 1920);
  const dividend = curFontSize / defaultFontSize;
  const svgIMg = new Image();
  if (curFrame === 0) {
    const text = "Test";
    const inputGroups: ParticleInput[] = isMobile
      ? [
          {
            image: images[3].image,
            xPos: "50% - 60px",
            yPos: "50% + 60px",
            scaleX: 0.6,
            scaleY: 0.6,
            //   color: "#f2f2f2",
          } as ParticleImageInput,
          {
            image: images[2].image,
            xPos: `50% + 60px`,
            yPos: "50% + 60px",
            scaleX: 0.6,
            scaleY: 0.6,
            //   color: "#f2f2f2",
          } as ParticleImageInput,
          {
            image: images[1].image,
            xPos: `50%`,
            yPos: "50% - 50px",
            scaleX: 0.6,
            scaleY: 0.6,
            //   color: "#f2f2f2",
          } as ParticleImageInput,
        ]
      : isTablet
      ? [
          {
            image: images[3].image,
            xPos: `50% - 100px`,
            yPos: `50% + 100px`,
            //   color: "#f2f2f2",
          } as ParticleImageInput,
          {
            image: images[2].image,
            xPos: `50% + 100px`,
            yPos: "50% + 100px",
            //   color: "#f2f2f2",
          } as ParticleImageInput,
          {
            image: images[1].image,
            xPos: `50%`,
            yPos: "50% - 85px",
            //   color: "#f2f2f2",
          } as ParticleImageInput,
        ]
      : [
          {
            image: images[3].image,
            xPos: `50%`,
            yPos: "50%",
            //   color: "#f2f2f2",
          } as ParticleImageInput,
          {
            image: images[2].image,
            xPos: `50% - 400px`,
            yPos: "50%",
            //   color: "#f2f2f2",
          } as ParticleImageInput,
          {
            image: images[1].image,
            xPos: `50% + 400px`,
            yPos: "50%",
            //   color: "#f2f2f2",
          } as ParticleImageInput,
        ];
    p?.addInputGroup({
      group: 0,
      xPos: "50%",
      yPos: `1000px`,
      action: {
        type: "both",
        xPos: "50%",
        yPos: `1000px`,
        mode: "set",
      },
      allocatedParticles: 20000,
      radius: isMobile ? 2 : 3,
      prtclDstRng: 0.5,
      resPerc: 100,
      removeWhite: false,
      inputs: inputGroups,

      clickCallback: (coords) => {
        if (coords) {
          const githubLoc = isMobile
            ? { x: 0, y: -55 }
            : isTablet
            ? { x: 0, y: -85 }
            : { x: 400, y: 0 };
          const linkedInLoc = isMobile
            ? { x: 60, y: 55 }
            : isTablet
            ? { x: 100, y: 85 }
            : { x: -400, y: 0 };
          const emailLoc = isMobile
            ? { x: -60, y: 55 }
            : isTablet
            ? { x: -100, y: 85 }
            : { x: 0, y: 0 };
          const disGithub = Math.sqrt(
            (coords.x - githubLoc.x) ** 2 + (coords.y - githubLoc.y) ** 2
          );
          const disLinkedIn = Math.sqrt(
            (coords.x - linkedInLoc.x) ** 2 + (coords.y - linkedInLoc.y) ** 2
          );
          const disEmail = Math.sqrt(
            (coords.x - emailLoc.x) ** 2 + (coords.y - emailLoc.y) ** 2
          );
          const windowSize =
            "width=" +
            window.innerWidth +
            ",height=" +
            window.innerHeight +
            ",scrollbars=no";
          if (
            disGithub < 98 &&
            disGithub < disEmail &&
            disGithub < disLinkedIn
          ) {
            if (isMobile) {
              window.open("https://github.com/tbpatj", "_blank", windowSize);
            } else {
              window.open("https://github.com/tbpatj");
            }
          } else if (
            disEmail < 98 &&
            disEmail < disGithub &&
            disEmail < disLinkedIn
          ) {
            setFormOpened(true);
          }
          if (
            disLinkedIn < 98 &&
            disLinkedIn < disGithub &&
            disLinkedIn < disEmail
          ) {
            window.open(
              "https://www.linkedin.com/in/timothy-luebke-252598224/",
              "_blank"
            );
          }
        }

        // if (!disabledRef.current) {
        //   p.disableGroups([0]);
        //   disabledRef.current = !disabledRef.current;
        //   setTimeout(() => {
        //     renderTitle(p, images, selected, disabledRef, 1);
        //   }, 1000);
        // } else {
        //   // p.enableGroups([0]);
        // }
      },
    });
  } else if (curFrame === 1) {
  } else if (curFrame === 2) {
  }
};
