import { CSSProperties, useContext } from "react";
import { PCContext } from "../../Context/PCContext";
import ParticleSelectSpinner from "./ParticleSelectSpinner";
import { SelectSpinnerOptions } from "./type";
import SelectSpinner2 from "./SelectSpinner2";

interface SelectSpinnerProps {
  useParticleV?: boolean;
  options: SelectSpinnerOptions[];
  angleIncrement?: number;
  offsetX?: number;
  onSelect?: (val: number) => void;
  dis?: number;
  fontSize?: string;
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const SelectSpinner: React.FC<SelectSpinnerProps> = ({
  useParticleV = true,
  options,
  angleIncrement,
  dis,
  fontSize,
  offsetX,
  onSelect,
  style,
  className,
  id,
}) => {
  const { pSupported } = useContext(PCContext);

  // if (useParticleV && pSupported) {
  //   return <ParticleSelectSpinner options={options} />;
  // }
  return (
    <>
      {options.length > 0 && (
        <SelectSpinner2
          angleIncrement={angleIncrement}
          style={style}
          className={className}
          fontSize={fontSize}
          offsetX={offsetX}
          dis={dis}
          options={options}
          onSelect={onSelect}
          id={id}
        />
      )}
    </>
    // <TextSpinner
    //   angleIncrement={angleIncrement}
    //   style={style}
    //   className={className}
    //   fontSize={fontSize}
    //   offsetX={offsetX}
    //   dis={dis}
    //   options={options}
    //   onSelect={onSelect}
    // ></TextSpinner>
  );
};

export default SelectSpinner;
