import React from "react";

interface SelectProps {
  value: string;
  options: { text: string; value: string }[];
  onChange: (value: string) => void;
  label?: string;
}

const Select: React.FC<SelectProps> = ({ options, onChange, value, label }) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <>
      {label && <label>{label}</label>}
      <select value={value} onChange={handleSelectChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </>
  );
};

export default Select;
