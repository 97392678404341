import { CarousalProps } from "./Carousal";
import { ReactComponent as ArrowIcon } from "../../SVGs/arrow.svg";
import { useMemo, useState } from "react";
import MyVideo from "../Visual/Video";
const CarousalHTML: React.FC<CarousalProps> = ({ items }) => {
  const [item, setItem] = useState(0);
  const [current, setCurrent] = useState(0);
  const [lastItem, setLastItem] = useState(0);
  //get the next items in the list of items.
  const getCurrentItems = () => {
    return [current - 1, current, current + 1];
  };
  const currentItems: any = useMemo(() => getCurrentItems(), [item]);

  const changeItem = (newItem: number) => {
    if (item !== newItem) {
      setLastItem(item + 0);
      if (newItem > item) setCurrent(current + 1);
      else setCurrent(current - 1);
      if (newItem >= items.length) {
        setItem(0);
      } else if (newItem < 0) {
        setItem(items.length - 1);
      } else {
        setItem(newItem);
      }
    }
  };
  return (
    <div className="carousal-container relative">
      {/* <div className="carousal-image-container"> */}
      {(currentItems ?? [])?.map((val: any, i: number) => {
        const isVideo = items?.[item]?.includes("video") && i === 1;
        const isImage = items?.[item]?.includes("image") && i === 1;
        const isOther = i !== 1;
        const lastImgSrc = items?.[lastItem]?.includes("video")
          ? items?.[lastItem]
              .replace("/videos", "/images/thumbnails")
              .split(".")
              .slice(-1) + ".png"
          : items?.[lastItem];
        return (
          <div
            key={`${val}-carousal-element`}
            className="carousal-element relative"
            style={{
              left: `${i === 0 ? -20 : i === 2 ? 120 : 50}%`,
              opacity: i === 1 ? 1 : 0,
            }}
          >
            {isOther && (
              <div className="carousal-image-container">
                <img className="carousal-image" src={lastImgSrc}></img>
              </div>
            )}
            {isImage && (
              <div className="carousal-image-container">
                <img
                  className="carousal-image"
                  src={i === 1 ? items?.[item] : items?.[lastItem]}
                ></img>
              </div>
            )}
            {isVideo && (
              <MyVideo
                className="carousal-video-element"
                src={i === 1 ? items?.[item] : items?.[lastItem]}
              />
            )}
          </div>
        );
      })}
      {/* <img className="carousal-image" src={items[0]}></img> */}
      {/* </div> */}
      <div className="carousal-button-container">
        {(items ?? []).map((val, i) => (
          <div
            onClick={() => changeItem(i)}
            key={`carousal-button-${i}`}
            className={`selection-button ${item === i ? "opened" : ""}`}
          ></div>
        ))}
      </div>
      <div
        className="carousal-next-button left"
        onClick={() => changeItem(item - 1)}
      >
        <ArrowIcon
          stroke="#cecece"
          width={40}
          style={{ transform: "rotate(90deg)" }}
          height={40}
          strokeWidth="2px"
        />
      </div>
      <div
        className="carousal-next-button right"
        onClick={() => changeItem(item + 1)}
      >
        <ArrowIcon
          stroke="#cecece"
          width={40}
          style={{ transform: "rotate(-90deg)" }}
          height={40}
          strokeWidth="2px"
        />
      </div>
    </div>
  );
};

// https://luebke.app/videos/switch/MiniBoss.mp4

export default CarousalHTML;

// import { useMemo, useState } from "react";
// import { CarousalProps } from "./Carousal";
// import MyVideo from "../Visual/Video";
// import { ReactComponent as ArrowIcon } from "../../SVGs/arrow.svg";

// const CarousalHTML: React.FC<CarousalProps> = ({ items }) => {
//   const [item, setItem] = useState(0);
//   const [current, setCurrent] = useState(0);
//   const [lastItem, setLastItem] = useState(0);
//   //get the next items in the list of items.
//   const getCurrentItems = () => {
//     return [current - 1, current, current + 1];
//   };
//   const currentItems: any = useMemo(() => getCurrentItems(), [item]);

//   const changeItem = (newItem: number) => {
//     if (item !== newItem) {
//       setLastItem(item + 0);
//       if (newItem > item) setCurrent(current + 1);
//       else setCurrent(current - 1);
//       if (newItem >= items.length) {
//         setItem(0);
//       } else if (newItem < 0) {
//         setItem(items.length - 1);
//       } else {
//         setItem(newItem);
//       }
//     }
//   };

//   return (
//     <div className="carousal-container">
//       <div className="relative carousal-element-container">
//         {(currentItems ?? [])?.map((val: any, i: number) => {
//           const isVideo = items?.[item]?.includes("video") && i === 1;
//           const isImage = items?.[item]?.includes("image") && i === 1;
//           const isOther = i !== 1;
//           const lastImgSrc = items?.[lastItem]?.includes("video")
//             ? items?.[lastItem]
//                 .replace("/videos", "/images/thumbnails")
//                 .split(".")
//                 .slice(-1) + ".png"
//             : items?.[lastItem];
//           return (
//             <div
//               key={`${val}-carousal-element`}
//               className="carousal-element relative"
//               style={{
//                 left: `${i === 0 ? -20 : i === 2 ? 120 : 50}%`,
//                 opacity: i === 1 ? 1 : 0,
//               }}
//             >
//               {isOther && (
//                 <img style={{ height: "100%" }} src={lastImgSrc}></img>
//               )}
//               {isImage && (
//                 <>
//                   <div
//                     className="carousal-next-button right"
//                     onClick={() => changeItem(item + 1)}
//                   >
//                     <ArrowIcon style={{ transform: "rotate(-90deg)" }} />
//                   </div>
//                   <div className="carousal-next-button left">
//                     <ArrowIcon style={{ transform: "rotate(90deg)" }} />
//                   </div>
//                   <img
//                     style={{ height: "100%" }}
//                     src={i === 1 ? items?.[item] : items?.[lastItem]}
//                   ></img>
//                 </>
//               )}
//               {isVideo && (
//                 <MyVideo
//                   className="carousal-video-element"
//                   src={i === 1 ? items?.[item] : items?.[lastItem]}
//                 />
//               )}
//             </div>
//           );
//         })}
//       </div>
//       <div className="carousal-button-container">
//         {(items ?? []).map((val, i) => (
//           <div
//             onClick={() => changeItem(i)}
//             key={`carousal-button-${i}`}
//             className={`selection-button ${item === i ? "opened" : ""}`}
//           ></div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default CarousalHTML;
