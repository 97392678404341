import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CollapsableTitle from "../../../Collapsable/CollapsableTitle";
import { DataContext } from "../../../../Context/DataContext";
import Modal from "../../../Modal/Modal";
import Pagination from "../../../Pagination/Pagination";
import Copy from "../../../Input/Copy";
import UploadEditorItem from "./UploadEditorItem";
import UploadEditorUploading from "./UploadEditorUploading";
import useWinSize from "../../../../Hooks/useWinSize";
import useSelector from "../../../../Hooks/Input/useSelector";
import DeleteConfirmModal from "../../../Modal/Modals/DeleteConfirmModal";
const baseURL = process.env.REACT_APP_BASE_URL;

interface ImageEditorProps {
  id: string;
  title: string;
  type: "image" | "video" | "file";
}

const UploadEditor: React.FC<ImageEditorProps> = ({ id, title, type }) => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState<{ [name: string]: string }[]>([]);
  const [previewOpened, setPreviewOpened] = useState(false);
  const [previewItemSrc, setPreviewItemSrc] = useState("");
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [deleteError, setDeleteError] = useState("");
  const { isMobile } = useWinSize();
  const { fetcher } = useContext(DataContext);
  const {
    selectClick,
    selectShiftClick,
    selectAll,
    clearSelected,
    selected,
    selectedAmt,
  } = useSelector({});

  const getItems = async () => {
    const results = await fetcher("/search", {
      method: "GET",
      params: {
        search: `${title.toLowerCase()}s`,
        s: search,
        page: page - 1,
        count: count,
      },
    });
    if (results?.data?.[`${title.toLowerCase()}s`]?.data) {
      setItems(results.data[`${title.toLowerCase()}s`].data);
      setTotalItems(results.data[`${title.toLowerCase()}s`].count);
    }
  };

  const deleteItems = async () => {
    const toDelItems = items.filter((val, i) => selected?.[i]);
    const results = await fetcher("/upload", {
      method: "DELETE",
      body: {
        type: `${title.toLowerCase()}s`,
        items: toDelItems,
      },
    });
    if (results?.data?.unresolved?.length === 0) {
      setDeleteModalOpened(false);
      getItems();
      clearSelected();
      setDeleteError("");
    } else if (
      results?.data?.unresolved?.length > 0 &&
      results?.data?.deleted?.length > 0
    ) {
      clearSelected();
      setDeleteError(
        `${results.data.deleted.length} items were deleted but ${results.data.unresolved.length} were not`
      );
    }
  };

  useEffect(() => {
    getItems();
  }, [search, page]);

  const newPreview = (source: string) => {
    setPreviewItemSrc(source);
    setPreviewOpened(true);
  };

  const getCol = useCallback(
    (col: number) => {
      let newList: { [name: string]: string }[] = [];
      if (isMobile) {
        if (col === 2) {
          return [];
        }
        for (let i = col; i < items.length; i += 2) {
          newList.push(items[i]);
        }
      } else {
        for (let i = col; i < items.length; i += 3) {
          newList.push(items[i]);
        }
      }

      return newList;
    },
    [isMobile, items]
  );

  const onFileClick =
    (index: number) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (e.shiftKey) {
        selectShiftClick(index);
      } else if (e.ctrlKey || e.button === 2) {
        e.preventDefault();
        e.stopPropagation();
        selectClick(index);
      }
    };
  const col1 = useMemo(() => getCol(0), [items, isMobile]);
  const col2 = useMemo(() => getCol(1), [items, isMobile]);
  const col3 = useMemo(() => getCol(2), [items, isMobile]);
  const cols = useMemo(() => [col1, col2, col3], [col1, col2, col3]);
  const isVideoOrImage = type === "video" || type === "image";
  return (
    <CollapsableTitle title={title + "s"}>
      <DeleteConfirmModal
        open={deleteModalOpened}
        error={deleteError}
        onCancel={() => {
          getItems();
          setDeleteModalOpened(false);
        }}
        onConfirm={() => deleteItems()}
      />
      <Modal onClose={() => setPreviewOpened(false)} opened={previewOpened}>
        {type === "image" && (
          <img className="upload-editor-preview" src={previewItemSrc}></img>
        )}
        {type === "video" && (
          <video
            controls
            className="upload-editor-preview"
            src={previewItemSrc}
          />
        )}
        <div className="upload-editor-preview-copy-container">
          <Copy value={previewItemSrc}></Copy>
        </div>
      </Modal>
      <UploadEditorUploading
        onClose={() => getItems()}
        title={title}
        onSearchChange={(val: string) => setSearch(val)}
      />
      {selectedAmt > 0 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <input
              type="checkbox"
              checked={selectedAmt === items.length}
              onChange={() => selectAll(items.length)}
            ></input>
            <span>selcted {selectedAmt}</span>
          </div>
          <span onClick={() => setDeleteModalOpened(true)}>delete</span>
        </div>
      )}
      <Pagination
        id={`${id}-pagination`}
        siblingCount={1}
        pageSize={count}
        currentPage={page}
        totalCount={totalItems}
        onPageChange={(page) => setPage(page)}
      />
      <div className={`editor-upload-container ${type}`}>
        {isVideoOrImage && (
          <>
            {cols.map((column, i) => (
              <div
                key={`${id}-editor-upload-container-${i}`}
                className="editor-upload-col"
              >
                {column.map((val, i2) => (
                  <UploadEditorItem
                    onSelect={() => selectClick(i2 * 3 + i)}
                    onShiftSelect={() => selectShiftClick(i2 * 3 + i)}
                    key={`${id}-editor-upload-item-${i2}-${i}`}
                    onPreview={newPreview}
                    type={type}
                    selecting={selectedAmt > 0}
                    selected={selected?.[i2 * 3 + i]}
                    filename={val.name}
                    url={`${baseURL}/${title.toLowerCase()}s/${val.name}`}
                  />
                ))}
              </div>
            ))}
          </>
        )}
        {type === "file" && items && (
          <>
            {items.map((val, i) => {
              return (
                <div
                  onContextMenu={onFileClick(i)}
                  onClick={onFileClick(i)}
                  key={`${id}-editor-upload-file-item-${val.name}-${i}`}
                  className="upload-editor-file-container"
                >
                  <div style={{ display: "flex" }}>
                    {selected?.[i] && (
                      <input
                        type="checkbox"
                        checked={true}
                        onChange={() => selectClick(i)}
                      />
                    )}
                    <div key={`${id}-editor-upload-item-${i}`}>{val.name}</div>
                  </div>
                  <div className="upload-editor-file-copy">
                    <Copy
                      value={`${baseURL}/${title.toLowerCase()}s/${val.name}`}
                    />
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </CollapsableTitle>
  );
};

export default UploadEditor;
