import { createRef, useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";

interface ScrollTransitionProps {
  children?: React.ReactNode | React.ReactNode[];
}

const ScrollTransition: React.FC<ScrollTransitionProps> = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 0, y: 100 }}
      whileInView={{ opacity: 1, x: 0, y: 0, transition: { duration: 0.4 } }}
      viewport={{ once: true, amount: 0.8 }}
    >
      {children}
    </motion.div>
  );
};

export default ScrollTransition;
