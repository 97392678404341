import { useCallback, useContext, useState } from "react";
import { ProjectData } from "../../Components/Editor/types";
import { DataContext } from "../../Context/DataContext";
import { MComponent } from "../../CMS/types";
import { initialContentData } from "../../Resources/Editor";

interface UseProjectDataProps {
  id?: string;
}

interface UseProjectData {
  data: ProjectData | undefined;
  content: MComponent;
  loadedData: boolean;
  loadProject: LoadProjectFunc;
  saveProjectContent: SaveProjectContentFunc;
  updateContent: UpdateContentFunc;
  updateCSS: UpdateCSSFunc;
}

export type LoadProjectFunc = () => void;

export type UpdateContentFunc = (content: MComponent) => void;

export type UpdateCSSFunc = (css: string) => void;

export type SaveProjectContentFunc = (
  content: MComponent,
  cb?: (response: any) => void
) => void;

const useProjectData: (props: UseProjectDataProps) => UseProjectData = ({
  id,
}) => {
  const [data, setData] = useState<ProjectData | undefined>();
  const [content, setContent] = useState<MComponent>(initialContentData);
  const [loadedData, setLoadedData] = useState(false);
  const { fetcher } = useContext(DataContext);
  //fetch the projects data
  const loadProject: LoadProjectFunc = useCallback(async () => {
    const projectData = await fetcher(`/posts/editor/${id}`);
    if (projectData?.data) {
      setData(projectData?.data);
      //get the json content that is a MComponent
      setContent(projectData?.data?.content);
      setLoadedData(true);
    }
  }, [setData, setContent, id, setLoadedData, fetcher]);

  const updateContent: UpdateContentFunc = useCallback(
    (content) => {
      setContent(content);
    },
    [setContent]
  );

  const saveProjectContent: SaveProjectContentFunc = useCallback(
    async (newContent, cb) => {
      const response = await fetcher(`/posts/content/${data?.content_id}`, {
        method: "PUT",
        body: { content: newContent, data: data?.data },
      });
      if (response) {
        setContent(newContent);
      }
      cb?.(response);
    },
    [content, setContent, data]
  );

  const updateCSS: UpdateCSSFunc = useCallback(
    (css) => {
      setData((curData) => {
        if (curData) {
          return { ...curData, data: { ...curData.data, css: css } };
        }
      });
    },
    [setData]
  );

  return {
    data,
    content,
    loadedData,
    loadProject,
    saveProjectContent,
    updateContent,
    updateCSS,
  };
};

export default useProjectData;
