interface ArcScrollProgressProps {
  progress: number;
  width?: number;
  radius?: number;
}

const ArcScrollProgress: React.FC<ArcScrollProgressProps> = ({
  progress,
  width = 2,
  radius = 400,
}) => {
  return (
    <div
      style={{
        transition: "width 0.5s",
        width: `${radius}px`,
        position: "relative",
      }}
    >
      <svg
        className="arc-svg"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={"M 50 90 A 40 40 0 0 0 50 10"}
          strokeWidth={width}
          fill="none"
          stroke="grey"
        />
      </svg>
      <svg
        className="arc-svg"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="arc-path"
          style={{
            transform: `rotate(${-160 + 180 * progress}deg)`,
          }}
          d={"M 63.68080573302675 87.58770483143633 A 40 40 0 0 0 50 10"}
          strokeWidth={width}
          fill="none"
          strokeLinecap="round"
          stroke="black"
        />
      </svg>
      <svg
        className="arc-svg"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{
            transform: `rotate(${-180 + 180 * progress * 0.5}deg)`,
          }}
          className="arc-path"
          d={"M 63.68080573302675 87.58770483143633 A 40 40 0 0 0 50 10"}
          strokeWidth={width}
          fill="none"
          strokeLinecap="round"
          stroke="black"
        />
      </svg>
    </div>
  );
};

export default ArcScrollProgress;
