import { useContext, useMemo, useState } from "react";
import Input from "../../Input/Input";
import Modal from "../Modal";
import Button from "../../Button/Button";
import TextArea from "../../Input/TextArea";
import { DataContext } from "../../../Context/DataContext";
import Spinner from "../../Loading/Spinner";
import { ReactComponent as CheckIcon } from "../../../SVGs/check.svg";

interface ContactModalProps {
  onClose: () => void;
  opened: boolean;
}

const ContactModal: React.FC<ContactModalProps> = ({ onClose, opened }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("fill-out");
  const { fetcher } = useContext(DataContext);

  const onSubmit = async () => {
    setStatus("loading");
    const response = await fetcher("/email/message", {
      method: "POST",
      body: {
        name,
        email,
        message,
      },
    });
    if (response) {
      setStatus("success");
      setTimeout(() => {
        onClose();
      }, 2000);
    } else {
      setStatus("error");
    }
  };

  const isButtonDisabled = useMemo(() => {
    if (name === "" || message === "" || email === "") {
      return true;
    }
    return false;
  }, [name, email, message]);

  const showForm =
    status === "fill-out" || status === "loading" || status === "error";

  return (
    <Modal onClose={() => onClose()} opened={opened}>
      <div className="contact-modal-container">
        {status === "success" && (
          <div className="contact-modal-success-container">
            <CheckIcon width={50} stroke="" />
            <h3>Success</h3>
          </div>
        )}
        {status === "loading" && (
          <div className="contact-modal-overlay">
            <Spinner />
          </div>
        )}
        {showForm && (
          <>
            <h3 className="contact-modal-heading-1">Get in Contact</h3>
            <h6 className="contact-modal-heading-2">
              Send me some details to get in touch
            </h6>
            <Input
              label="Name"
              placeholder="Write your name"
              id="contact-modal-name"
              value={name}
              onChange={(val) => setName(val)}
            />
            <Input
              placeholder="johnsmith@gmail.com"
              label="Email"
              id="contact-modal-email"
              value={email}
              onChange={(val) => setEmail(val)}
            />
            <TextArea
              rows={5}
              label="Message"
              id="contact-modal-message"
              value={message}
              onChange={(val) => setMessage(val)}
            />
            {status === "error" && (
              <div className="contact-error-message">
                Error: could not record contact message, please try again later.
              </div>
            )}
            <div
              style={{ display: "flex", width: "100%", justifyContent: "end" }}
            >
              <Button
                disabled={isButtonDisabled}
                onClick={() => onSubmit()}
                style={{ width: "45%", padding: "7px 20px", marginTop: "20px" }}
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ContactModal;
