import { useEffect } from "react";
import { MouseCursor } from "../types/mouse";

export type MouseRef = React.MutableRefObject<MouseCursor>;
type CanvasRef = React.RefObject<HTMLCanvasElement | undefined>;

export type ReactMouseEvent = React.MouseEvent<HTMLCanvasElement, MouseEvent>;

const useMouseCursor: (ref: MouseRef, canvasRef: CanvasRef) => void = (
  ref,
  canvasRef
) => {
  const updateCursorPos = (x: number, y: number) => {
    let dx = 0;
    let dy = 0;
    const now = new Date();
    //make sure we aren't recording the user moving the cursor out of the canvas and then to another position on the other side of the canvas making things flip out
    if (now.getTime() - ref.current.lastRecordedTime.getTime() < 100) {
      dx = x - ref.current.x;
      dy = y - ref.current.y;
    }
    const lastX = ref.current.x;
    const lastY = ref.current.y;
    //get the velocity vector properties
    const magSqr = dx * dx + dy * dy;
    const mag = Math.sqrt(magSqr);
    const nDx = mag === 0 ? mag : dx / mag;
    const nDy = mag === 0 ? mag : dy / mag;
    ref.current = {
      ...ref.current,
      x,
      y,
      lastX,
      lastY,
      dx,
      dy,
      magSqr: magSqr,
      mag: mag,
      nDx,
      nDy,
      lastRecordedTime: now,
    } as MouseCursor;
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (canvasRef.current) {
        let rect = canvasRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        updateCursorPos(x, y);
      }
    };

    const handleScrolling = (e: any) => {
      ref.current.scrollDY = ref.current.scrollPosY - window.scrollY;
      ref.current.scrollPosY = window.scrollY;
    };

    const handleMouseUp = (e: MouseEvent) => {
      if (e.button === 0) {
        ref.current = { ...ref.current, leftMouseDown: false };
      } else if (e.button === 2) {
        ref.current = { ...ref.current, rightMouseDown: false };
      }
    };
    const handleMouseDown = (e: MouseEvent) => {
      if (e.button === 0) {
        ref.current = { ...ref.current, leftMouseDown: true, leftClick: true };
      } else if (e.button === 2)
        ref.current = {
          ...ref.current,
          rightMouseDown: true,
          rightClick: true,
        };
    };

    const handleTouchMove = (e: any) => {
      e as TouchEvent;
      //could do something with multiple touches, like disable scroll if only one tap so the user can interact
      updateCursorPos(e.touches[0].clientX, e.touches[0].clientY);
    };

    window.addEventListener("scroll", handleScrolling);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleTouchMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("scroll", handleScrolling);
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);
};

export default useMouseCursor;
